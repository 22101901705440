import { Box, type BoxProps } from "@hotelengine/atlas-web";

import styled, { css } from "styled-components";

export const CancelledBannerContainer = styled<React.FC<BoxProps>>(Box)<{ $isRefresh?: boolean }>`
  width: 100%;
  max-height: ${({ theme }) => theme.spacing[64]};
  margin-bottom: ${({ theme, $isRefresh }) => theme.spacing[$isRefresh ? 0 : 12]};
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[0]}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.accentRedLightest};

  ${({ $isRefresh, theme }) =>
    !!$isRefresh &&
    css`
        border-bottom: ${`${theme.borderWidth.normal} solid ${theme.colors.accentRedLight}`}
    `}
`;
