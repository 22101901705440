import type { ICourierNotificationInbox } from "@hotel-engine/types/courierNotifications";

import { NotificationInboxHeader } from "./NotificationInboxHeader";
import { NotificationInboxContent } from "./NotificationInboxContent";
import { useNotificationsInbox } from "../useNotificationsInbox";
import * as Styled from "./styles";

const NotificationInbox = ({
  fetchMessages,
  hasUnreadMessages,
  isError,
  isLoading,
  markAllAsRead,
  markMessageArchived,
  markMessageRead,
  markMessageUnread,
  messages = [],
  accountType,
  isRedesign24,
}: ICourierNotificationInbox & { isRedesign24?: boolean }) => {
  const { isMobile, hasMessages, isEmpty } = useNotificationsInbox({
    accountType,
    fetchMessages,
    hasUnreadMessages,
    messages,
  });

  return (
    <Styled.NotificationInbox data-testid="notification-inbox">
      <NotificationInboxHeader
        isMobile={isMobile}
        hasMessages={hasMessages}
        markAllAsRead={markAllAsRead}
      />

      <NotificationInboxContent
        isLoading={isLoading}
        isEmpty={isEmpty}
        isError={isError}
        messages={messages}
        markMessageArchived={markMessageArchived}
        markMessageRead={markMessageRead}
        markMessageUnread={markMessageUnread}
        isRedesign24={isRedesign24}
      />
    </Styled.NotificationInbox>
  );
};

export default NotificationInbox;
