import { useCallback, useState } from "react";
import { useNavigate, useLocation } from "@hotel-engine/lib/react-router-dom";
import { Box, TopNav, TopNavLink } from "@hotelengine/atlas-web";

import { ampli } from "ampli";
import {
  useBreakpoint,
  useSearchParams,
  useURLParams,
  type ISearchAndRoomRateID,
} from "@hotel-engine/hooks";
import { Unsafe } from "@hotel-engine/data";
import { TopNavMenu } from "./redesignComponents/TopNavMenu";
import { RightAccessories } from "./redesignComponents/RightAccessories";
import {
  getNavLinks,
  removeParameter,
  buildQueryString,
  getUserRoleEnabledFeatures,
  getCurrentState,
} from "../helpers";
import { triggerAmplitudeEvent } from "@hotel-engine/scripts/hooks";
import { routes, GROUPS } from "@hotel-engine/constants";
import { InviteUserModal } from "@hotel-engine/app/modals/InviteUserModal/InviteUserModal";
import { SignInModal } from "@hotel-engine/app/modals/SignInModal";
import {
  EmailConfirmationWarningModal,
  type IEmailLoginDid403,
} from "@hotel-engine/app/modals/EmailConfirmationWarningModal";
import { PersonalAccountCreationModal } from "pages/DashboardLegacy/components/PersonalAccountCreationCta/PersonalAccountCreationModal";

import { useAccountMode } from "@hotel-engine/react-query/users/useAccountMode";
import type { User } from "@hotel-engine/types/user";
import { type ILinks, headerBackgroundColorOverrides } from "../constants";
import { Logo } from "@hotel-engine/app/Header/components/redesignComponents/Logo";
import { useAppSelector } from "store/hooks";
import { GlobalNavWrapper, StyledLink } from "./userStyles";

const HeaderRedesign24 = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const userId = user?.id;
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [isPersonalAccountCreationModalOpen, setIsPersonalAccountCreationModalOpen] =
    useState(false);
  // Indicates that the personal account that the user attempted to login with is not email-confirmed and to render the corresponding modal //
  const [emailLoginDid403, setEmailLoginDid403] = useState({
    did403: false,
    email: "",
  });
  const isMobile = useBreakpoint("lg", "max");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activePathId = getCurrentState(pathname);
  const urlParams = useSearchParams<{
    resetPassword?: string;
    toggleAccount?: User.AccountType;
  }>();
  // Grabs necessary info for usage tracking //
  const {
    params: { propertyId },
    search: { s: searchId, roomRateId },
  } = useURLParams<ISearchAndRoomRateID>();

  const isPsamOn = user?.hasGroupsSpecialization;

  const handleSwitchFailure = useCallback(() => setIsSignInModalOpen(true), []);
  const [mode, toggleAccountMode] = useAccountMode({
    onFailure: handleSwitchFailure,
  });

  const handleOnSwitchEvent = useCallback(
    (accountType: User.AccountType) => {
      triggerAmplitudeEvent(
        accountType === "personal"
          ? "clickSwitchToPersonalAccount"
          : "clickSwitchToBusinessAccount",
        { userId }
      );
    },
    [userId]
  );

  const handleSwitchAccounts = useCallback(
    (accountType: User.AccountType) => {
      handleOnSwitchEvent(accountType);
      toggleAccountMode().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    },
    [handleOnSwitchEvent, toggleAccountMode]
  );

  const handleTrackLocation = useCallback(() => {
    () => {
      if (location.pathname === routes.search) {
        ampli.clickHomepageFromSearchResults({
          searchId: Number(searchId),
        });
      }

      // eslint-disable-next-line no-useless-escape
      if (new RegExp(`${routes.properties}\/.*\/book`).test(location.pathname)) {
        ampli.clickHomepageFromCheckout({
          propertyId: Number(propertyId),
          searchId: Number(searchId),
          roomRateId: Number(roomRateId),
        });
      }

      if (/\/properties\/\d+/g.test(location.pathname)) {
        ampli.clickHomepageFromPropertyDetails({
          searchId: Number(searchId),
          propertyId: propertyId ? Number(propertyId) : undefined,
        });
      }
    };
  }, [propertyId, roomRateId, searchId]);

  const onInviteTraveler = useCallback(() => setIsInviteUserModalOpen(true), []);
  const onCancelInviteUser = useCallback((resetForm: () => void) => {
    setIsInviteUserModalOpen(false);
    resetForm();
  }, []);
  const onPersonalAccountCreation = useCallback(() => {
    setIsPersonalAccountCreationModalOpen(true);
    triggerAmplitudeEvent("clickAddPersonalAccountInMenu", { userId });
  }, [userId]);
  const onCancelPersonalAccountCreation = useCallback(() => {
    if (urlParams.toggleAccount) {
      navigate("/", { replace: true });
    }
    setIsPersonalAccountCreationModalOpen(false);
  }, [navigate, urlParams.toggleAccount]);
  const handleEmailLoginDid403 = useCallback((data: IEmailLoginDid403) => {
    setIsSignInModalOpen(false);
    setEmailLoginDid403(data);
  }, []);
  const onCloseEmailConfirmationWarningModal = useCallback(
    () => setEmailLoginDid403({ ...emailLoginDid403, did403: false }),
    [emailLoginDid403]
  );
  const onCancelAccountSwitch = useCallback(() => {
    if (urlParams.toggleAccount) {
      navigate("/", { replace: true });
    }
    setIsSignInModalOpen(false);
  }, [urlParams.toggleAccount, navigate]);

  if (!user) {
    return null;
  }

  const navLinks = getNavLinks(user);
  const { canCreatePersonalAccount } = getUserRoleEnabledFeatures(user);

  if (urlParams?.resetPassword) {
    /** Remove resetPassword=true from url when comes from a
     * reset password redirect */
    const newParams = removeParameter(urlParams, "resetPassword");
    const newQueryString = buildQueryString(newParams);
    navigate(newQueryString ? `/?${newQueryString}` : "/", {
      replace: true,
    });
  }

  if (urlParams?.toggleAccount) {
    handleSwitchAccounts(urlParams.toggleAccount);
    navigate("/", { replace: true });
  }

  return (
    <GlobalNavWrapper $globalNavBackgroundColorOverride={headerBackgroundColorOverrides[pathname]}>
      <TopNav
        leftAccessory={
          <Box
            gap={12}
            onClick={handleTrackLocation}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Logo />
          </Box>
        }
        rightAccessory={
          <RightAccessories
            isMobile={isMobile}
            mode={mode}
            handleSwitchAccounts={handleSwitchAccounts}
            onInviteTraveler={onInviteTraveler}
            onPersonalAccountCreation={onPersonalAccountCreation}
          />
        }
      >
        {navLinks.reduce<(ILinks | JSX.Element)[]>((acc, link) => {
          const { id, name, route, type, redesignName } = link;
          if (["trends", "members"].includes(id)) return acc;

          if (type === "menu-trigger" || (id === GROUPS && isPsamOn)) {
            return [
              ...acc,
              <TopNavMenu
                key={id}
                isMobile={isMobile}
                activePathId={activePathId}
                triggerLabel={redesignName || name}
                cards={link.subMenuItems ?? []}
              />,
            ];
          }

          return [
            ...acc,
            <TopNavLink asChild isActive={activePathId === id} key={id}>
              <StyledLink to={route}>{redesignName || name}</StyledLink>
            </TopNavLink>,
          ];
        }, [])}
        <InviteUserModal visible={isInviteUserModalOpen} onCancel={onCancelInviteUser} />
        {!!canCreatePersonalAccount && (
          <PersonalAccountCreationModal
            visible={isPersonalAccountCreationModalOpen}
            onClose={onCancelPersonalAccountCreation}
            triggeredBy="HEADER_SETTINGS"
          />
        )}
        <SignInModal
          visible={isSignInModalOpen}
          onCancel={onCancelAccountSwitch}
          setEmailLoginDid403={handleEmailLoginDid403}
        />
        <EmailConfirmationWarningModal
          email={emailLoginDid403.email}
          onClose={onCloseEmailConfirmationWarningModal}
          visible={emailLoginDid403.did403}
        />
      </TopNav>
    </GlobalNavWrapper>
  );
};

export default HeaderRedesign24;
