import { Box, type BoxProps } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const DepartureContainer = styled<React.FC<BoxProps>>(Box)`
	display: flex;
	justify-content: flex-end;
  align-items: center;
`;

export const ArrivalContainer = styled<React.FC<BoxProps>>(Box)`
	display: flex;
	justify-content: flex-start;
  align-items: center;
`;

export const ArrowContainer = styled<React.FC<BoxProps>>(Box)`
  background-color: ${({ theme }) => theme.colors.accentGrayLightest};
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled<React.FC<BoxProps>>(Box)`
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 100%;
`;
