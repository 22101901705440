import type { Locations } from "../../GoogleFlightMap/useGoogleFlightLocations";
import type { AirportMarkerData } from ".";

export const dedupeLocations = (locations: Locations[]) => {
  return locations.reduce((acc: Locations[], cur: Locations) => {
    const originLat = cur.origin?.lat;
    const originLng = cur.origin?.lng;
    const destinationLat = cur.destination?.lat;
    const destinationLng = cur.destination?.lng;

    // If either of these cases are true, we don't need to "include" that slice for drawing paths and pins
    const matchingSlice = acc.find((accItem) => {
      const locationsAreSame =
        accItem.destination?.lat === destinationLat &&
        accItem.destination?.lng === destinationLng &&
        accItem.origin?.lat === originLat &&
        accItem.origin?.lng === originLng;

      const locationsAreFlipped =
        accItem.destination?.lat === originLat &&
        accItem.destination?.lng === originLng &&
        accItem.origin?.lat === destinationLat &&
        accItem.origin?.lng === destinationLng;

      return locationsAreSame || locationsAreFlipped;
    });

    if (!matchingSlice) {
      acc.push(cur);
    }

    return acc;
  }, []);
};

export const getAirportMarkerData = (dedupedSlices: Locations[]) => {
  return dedupedSlices.reduce((acc: AirportMarkerData[], slice) => {
    const invertOrigin = (slice.origin?.lat || 0) < (slice.destination?.lat || 0);
    const invertDestination = (slice.destination?.lat || 0) < (slice.origin?.lat || 0);

    const origin = {
      position: { lat: slice.origin?.lat || 0, lng: slice.origin?.lng || 0 },
      airport: slice.origin?.address || "",
      inverted: invertOrigin,
    };
    const destination = {
      position: { lat: slice.destination?.lat || 0, lng: slice.destination?.lng || 0 },
      airport: slice.destination?.address || "",
      inverted: invertDestination,
    };

    /** Here we pull out any duplicated locations that didn't get removed as part of a fully duplicated slice
     * so that we don't render more than one marker for the same location
     */
    const originMatch = acc.find(
      (accItem) =>
        origin.position.lat === accItem.position.lat && origin.position.lng === accItem.position.lng
    );
    const destinationMatch = acc.find(
      (accItem) =>
        destination.position.lat === accItem.position.lat &&
        destination.position.lng === accItem.position.lng
    );

    if (!originMatch) {
      acc.push(origin);
    }

    if (!destinationMatch) {
      acc.push(destination);
    }

    return acc;
  }, []);
};
