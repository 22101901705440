import { Box, BrandLogo, Typography } from "@hotelengine/atlas-web";
import * as Styled from "./styles";
import { useBreakpoint } from "@hotel-engine/hooks";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

const MarketingBanners = () => {
  const isExtraLarge = useBreakpoint("xl");
  const { COMPANY_NAME, COMPANY_URL } = useCompanyName();
  const isRebrandLogoOn = useIsFeatureFlagOn("rebrand_logo_copy");

  return (
    <a target="_blank" rel="noreferrer" href={`https://www.${COMPANY_URL}/social-responsibility/`}>
      <Styled.MarketingBannerContainer>
        {isRebrandLogoOn ? (
          <Box display="flex" justifyContent="center" marginBottom={16} marginTop={8}>
            <BrandLogo name="engine-for-good--lockup" />
          </Box>
        ) : (
          <Styled.CSRLogo
            src={"/assets/HE_CSR_logo.svg"}
            alt={`${COMPANY_NAME} Corporate Social Responsibility Logo`}
          />
        )}
        <Typography
          as="h1"
          display="block"
          variant={isExtraLarge ? "heading/sm" : "heading/xs"}
          color="foregroundPrimary"
          style={{ textAlign: "center" }}
        >
          Your booking makes a difference.
        </Typography>
        <Typography
          as="p"
          display="block"
          variant={isExtraLarge ? "body/sm" : "body/xs"}
          color="foregroundPrimary"
          style={{ textAlign: "center" }}
        >
          We support non-profit organizations in the areas of environment, women and children,
          education, and veterans. Together, with each booking, we can continue making an impact.
        </Typography>
      </Styled.MarketingBannerContainer>
    </a>
  );
};

export default MarketingBanners;
