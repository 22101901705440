type BookingType = "car" | "flight" | "hotel";
type IconType = "car-side" | "plane" | "hotel";

export function getBookingType(bookingId: string): BookingType {
  if (/^F-/.test(bookingId)) {
    return "flight";
  }
  if (/^C-/.test(bookingId)) {
    return "car";
  }

  return "hotel";
}

export function getBookingIcon(bookingId: string): IconType {
  if (/^F-/.test(bookingId)) {
    return "plane";
  }
  if (/^C-/.test(bookingId)) {
    return "car-side";
  }

  return "hotel";
}
