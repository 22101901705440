import type { IResult, ISearchRatesServiceOutput } from "@hotel-engine/types/search";
import type { IResults, ISearchResultsState } from "store/Search/SearchResults/types";
import { SortOptions } from "store/Search/constants";

/**
 * @param validSpotlightPropertyIds Spotlight properties that are not hidden or filtered out
 * @returns Random spotlight property id from the valid spotlight properties
 */
export const selectRandomSpotlightProperty = (validSpotlightPropertyIds: number[]) => {
  const randomIndex = Math.floor(Math.random() * validSpotlightPropertyIds.length);
  const selectedResultId = validSpotlightPropertyIds[randomIndex];

  return selectedResultId;
};

/**
 * orderSpotlightPreferred aims to sort spotlight first and preferred in second
 * @param a Result to compare
 * @param b Result to compare
 * @returns number 0, 1, or -1
 */
const orderSpotlightPreferred = (a: IResult, b: IResult) => {
  const spotlightA = a.spotlight || false;
  const spotlightB = b.spotlight || false;
  const preferredA = a.preferred || false;
  const preferredB = b.preferred || false;

  if (spotlightB !== spotlightA) {
    return spotlightB ? 1 : -1;
  }

  if (preferredB !== preferredA) {
    return preferredB ? 1 : -1;
  }

  return 0;
};

interface IBaseSortMethodArgs {
  ids: number[];
  results: IResults;
}

export type SortMethod = (props: IBaseSortMethodArgs) => number[];
export const sortMethodMap: Record<SortOptions, SortMethod> = {
  [SortOptions.BestValue]: ({ ids, results }: IBaseSortMethodArgs) => {
    return [...ids].sort((firstId, secondId) => {
      const a = results[firstId];
      const b = results[secondId];

      const spotlightAndPreferredOrder = orderSpotlightPreferred(a, b);

      if (spotlightAndPreferredOrder !== 0) return spotlightAndPreferredOrder;

      const savingsDiff = (b.savePercent ?? 0) - (a.savePercent ?? 0);

      if (savingsDiff > 0) {
        return 1;
      }

      if (savingsDiff < 0) {
        return -1;
      }

      return (b.customerRate ?? 0) - (a.customerRate ?? 0);
    });
  },
  [SortOptions.Recommended]: ({ ids, results }: IBaseSortMethodArgs) => {
    return [...ids].sort((firstId, secondId) => {
      const a = results[firstId];
      const b = results[secondId];

      const spotlightAndPreferredOrder = orderSpotlightPreferred(a, b);

      if (spotlightAndPreferredOrder !== 0) return spotlightAndPreferredOrder;

      if (b.propertyScore === a.propertyScore) {
        // Sort by best value if scores are equal
        const savingsDiff = (b.savePercent ?? 0) - (a.savePercent ?? 0);
        if (savingsDiff > 0) return 1;
        if (savingsDiff < 0) return -1;

        // if best value is equal, sort by price low to high
        const customerRateDiff = (a.customerRate ?? 0) - (b.customerRate ?? 0);
        if (customerRateDiff !== 0) return customerRateDiff;

        // if price is equal, sort alphabetically
        return a.name.localeCompare(b.name);
      }

      return (b.propertyScore ?? 0) - (a.propertyScore ?? 0);
    });
  },
  [SortOptions.Distance]: ({ ids, results }: IBaseSortMethodArgs) => {
    return [...ids].sort((firstId, secondId) => {
      const a = results[firstId];
      const b = results[secondId];

      const spotlightAndPreferredOrder = orderSpotlightPreferred(a, b);

      if (spotlightAndPreferredOrder !== 0) return spotlightAndPreferredOrder;

      return a.distance - b.distance;
    });
  },

  [SortOptions.PriceLowToHigh]: ({ ids, results }: IBaseSortMethodArgs) => {
    return [...ids].sort((firstId, secondId) => {
      const a = results[firstId];
      const b = results[secondId];

      const spotlightAndPreferredOrder = orderSpotlightPreferred(a, b);

      if (spotlightAndPreferredOrder !== 0) return spotlightAndPreferredOrder;

      return (a.customerRate ?? 0) - (b.customerRate ?? 0);
    });
  },

  [SortOptions.PriceHighToLow]: ({ ids, results }: IBaseSortMethodArgs) => {
    return [...ids].sort((firstId, secondId) => {
      const a = results[firstId];
      const b = results[secondId];

      const spotlightAndPreferredOrder = orderSpotlightPreferred(a, b);

      if (spotlightAndPreferredOrder !== 0) return spotlightAndPreferredOrder;

      return (b.customerRate ?? 0) - (a.customerRate ?? 0);
    });
  },

  [SortOptions.StarsLowest]: ({ ids, results }: IBaseSortMethodArgs) => {
    return [...ids].sort((firstId, secondId) => {
      const a = results[firstId];
      const b = results[secondId];

      const spotlightAndPreferredOrder = orderSpotlightPreferred(a, b);

      if (spotlightAndPreferredOrder !== 0) return spotlightAndPreferredOrder;

      return (a.starRating ?? 0) - (b.starRating ?? 0);
    });
  },

  [SortOptions.StarsHighest]: ({ ids, results }: IBaseSortMethodArgs) => {
    return [...ids].sort((firstId, secondId) => {
      const a = results[firstId];
      const b = results[secondId];

      const spotlightAndPreferredOrder = orderSpotlightPreferred(a, b);

      if (spotlightAndPreferredOrder !== 0) return spotlightAndPreferredOrder;

      return (b.starRating ?? 0) - (a.starRating ?? 0);
    });
  },

  [SortOptions.GuestRatingHighest]: ({ ids, results }: IBaseSortMethodArgs) => {
    return [...ids].sort((firstId, secondId) => {
      const a = results[firstId];
      const b = results[secondId];

      const spotlightAndPreferredOrder = orderSpotlightPreferred(a, b);

      if (spotlightAndPreferredOrder !== 0) return spotlightAndPreferredOrder;

      return (b.rating ?? 0) - (a.rating ?? 0);
    });
  },
};
/**
 * Updates a result in the stream with new data.
 *
 * @param currentResults - The current set of results.
 * @param mergedPropertyIdsPreferredSet - Set of property IDs marked as preferred.
 * @param mergedPropertyIdsHiddenSet - Set of property IDs marked as hidden.
 * @param propertyIdsSpotlightSet - Set of property IDs marked as spotlight.
 * @param visibleHiddenResultIdsSet - Set of result IDs that are hidden.
 * @param visibleResultIdsSet - Set of result IDs that are visible.
 * @param newResult - The new result data to update in the state.
 */

export const updateStreamResult = (
  currentResults: IResults,
  mergedPropertyIdsPreferredSet: Set<number>,
  mergedPropertyIdsHiddenSet: Set<number>,
  propertyIdsSpotlightSet: Set<number>,
  visibleHiddenResultIdsSet: Set<number>,
  visibleResultIdsSet: Set<number>,
  newResult: IResult
) => {
  const { propertyId } = newResult;

  // Merge in result
  if (currentResults[propertyId]) {
    currentResults[propertyId] = {
      ...currentResults[propertyId],
      ...newResult,
    };
  } else {
    currentResults[propertyId] = { ...newResult };
  }

  // Result is not expired if received in new search
  currentResults[propertyId].expired = false;
  currentResults[propertyId].preferred = mergedPropertyIdsPreferredSet.has(propertyId);

  const isSpotlight = newResult.spotlight;

  const isHidden = mergedPropertyIdsHiddenSet.has(propertyId);
  currentResults[propertyId].hidden = isHidden;

  if (isHidden) {
    visibleHiddenResultIdsSet.add(propertyId);
    return;
  }

  // Add to possible spotlights to select from later
  if (isSpotlight) {
    propertyIdsSpotlightSet.add(propertyId);

    // Set spotlight to false for random selection later
    currentResults[propertyId].spotlight = false;
  }

  visibleResultIdsSet.add(propertyId);
};

/**
 * Update search detail fields in received state
 *
 * @param state - The current mutable SearchResults state
 * @param searchData - The search response payload
 */
export const setSearchDetails = (
  state: ISearchResultsState,
  searchData: ISearchRatesServiceOutput
) => {
  const {
    checkIn,
    checkOut,
    effectiveSearchRadius,
    effectiveSearchRadiusUnit,
    guestCount,
    id,
    latitude,
    location,
    longitude,
    loyaltyEligible,
    outOfPolicyCount,
    outOfPolicyReasons,
    radius,
    roomCount,
    suppliers,
  } = searchData;

  Object.assign(state, {
    checkIn,
    checkOut,
    effectiveSearchRadius: effectiveSearchRadius || null,
    effectiveSearchRadiusUnit: effectiveSearchRadiusUnit || null,
    guestCount,
    id,
    latitude,
    location: location || null,
    longitude,
    loyaltyEligible,
    outOfPolicyCount: outOfPolicyCount || null,
    outOfPolicyReasons: outOfPolicyReasons || [],
    radius,
    roomCount,
    suppliers: suppliers || [],
  });
};

export const getShortClientProgramName = (clientProgramName) => {
  if (clientProgramName.length > 20) {
    return `${clientProgramName.substr(0, 20)}...`;
  }
  return clientProgramName;
};
