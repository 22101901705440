import type { MouseEvent } from "react";
import { useMemo, useState } from "react";

import { useFormikContext } from "formik";
import type { Moment } from "moment";
import { CalendarDay, DateRangePicker, DayPickerRangeController } from "react-dates";
import {
  END_DATE,
  HORIZONTAL_ORIENTATION,
  START_DATE,
  VERTICAL_SCROLLABLE,
} from "react-dates/constants";

import { Icon, Typography, type TypographyVariant } from "@hotelengine/atlas-web";
import FullscreenModal from "@hotel-engine/common/FullscreenModal";
import { earliestAllowedCheckinDate } from "@hotel-engine/constants";
import { dateRangePickerShortFormats } from "@hotel-engine/constants/dateRangePickerSelections";
import { useFormatDate } from "@hotel-engine/hooks/useFormatDate";
import type { ISearchFormValues } from "@hotel-engine/types/search";
import config from "config";
import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";

type FocusedInput = END_DATE | START_DATE | null;

interface IDateSelectorInputProps {
  className?: string;
  inputWidth?: string;
  size?: "md" | "lg";
  textVariant?: TypographyVariant;
  isMobile?: boolean;
  margin?: string;
  handleAmplitude?: (newCheckIn: string | undefined, newCheckOut: string | undefined) => void;
}

export const DateSelectorInput = ({
  className,
  inputWidth,
  size = "md",
  isMobile,
  margin,
  handleAmplitude,
}: IDateSelectorInputProps) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null);
  const {
    business: { preferredDateFormat },
  } = useAppSelector((state) => state.Auth.user) || {
    business: { preferredDateFormat: "mdy" },
  };

  const { values, setFieldValue, errors, setFieldTouched, submitCount } =
    useFormikContext<ISearchFormValues>();

  const checkInError = !!errors.checkIn && submitCount > 0;
  const checkOutError = !!errors.checkOut && submitCount > 0;

  const checkInDate = useFormatDate(values.checkIn, "MM/DD/YY");
  const checkOutDate = useFormatDate(values.checkOut, "MM/DD/YY");

  const isForCheckOut = !!values.checkIn && focusedInput === END_DATE;

  const maxDate = useMemo(() => {
    // The max check in is the max range minus one day to allow checking out on the max date.
    return earliestAllowedCheckinDate
      .clone()
      .add(isForCheckOut ? config.maxSearchRange : config.maxSearchRange - 1, "days");
  }, [isForCheckOut]);

  /* Disable any dates that are:
    - Before today's date
    - Outside of the max search range
    - Any checkout date past the max number of nights
    */
  const isDateOutOfRange = (date: Moment) => {
    const isDateBeforeRange = date.isBefore(earliestAllowedCheckinDate, "day");

    if (isDateBeforeRange) return true;

    const isDateAfterRange = date.isAfter(maxDate, "day");

    if (isDateAfterRange) return true;

    if (!isForCheckOut) return false;

    const maxCheckOutDate = values.checkIn.clone().add(config.maxNights, "days");
    const isDateAfterMaxCheckOut = date.isAfter(maxCheckOutDate, "day");

    return isDateAfterMaxCheckOut;
  };

  const calendarChangeHandler = (startDate: Moment | null, endDate: Moment | null) => {
    if (startDate && endDate && endDate.diff(startDate, "days") > config.maxNights) {
      setFieldValue("checkOut", null);
    } else {
      setFieldValue("checkOut", endDate);
      // Here we must setFieldTouched in a timeout so that the value isn't out of date
      // https://github.com/formium/formik/issues/2059#issuecomment-612733378
      setTimeout(() => setFieldTouched("checkOut", true));
    }
    setFieldValue("checkIn", startDate);
    setTimeout(() => setFieldTouched("checkIn", true));
  };

  if (isMobile) {
    const clickCheckOut = (e: MouseEvent) => {
      e.stopPropagation();
      setFocusedInput(END_DATE);
    };

    const dates = (
      <Styled.MobileDateSelectorInput
        onClick={() => setFocusedInput(START_DATE)}
        className={className}
        inputWidth={inputWidth}
        margin={margin}
        size={size}
      >
        <Icon name="calendar--solid" color="foregroundSecondary" style={{ marginRight: 10 }} />
        <Styled.ButtonReset
          onClick={() => setFocusedInput(START_DATE)}
          data-testid="checkInButton"
          type="button"
        >
          {checkInDate ?? "Check In"}
        </Styled.ButtonReset>
        <Styled.SeparatorArrow />
        <Styled.CheckOutDate
          onClick={clickCheckOut}
          $checkOutError={checkOutError}
          data-testid="checkOutButton"
          type="button"
        >
          {checkOutDate ?? "Check Out"}
        </Styled.CheckOutDate>
      </Styled.MobileDateSelectorInput>
    );

    const mobileCalendar = (
      <Styled.DateCalendar isFocused={true}>
        <DayPickerRangeController
          startDate={values.checkIn}
          endDate={values.checkOut}
          onDatesChange={({ startDate, endDate }) => {
            handleAmplitude?.(startDate?.toJSON(), endDate?.toJSON());
            calendarChangeHandler(startDate, endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={(focus: FocusedInput) => setFocusedInput(focus)}
          initialVisibleMonth={() => earliestAllowedCheckinDate}
          isOutsideRange={isDateOutOfRange}
          noBorder
          orientation={VERTICAL_SCROLLABLE}
          numberOfMonths={18}
          hideKeyboardShortcutsPanel
          keepOpenOnDateSelect
          navPrev={<div />}
          navNext={<div />}
        />
      </Styled.DateCalendar>
    );

    return (
      <>
        {dates}
        <FullscreenModal
          visible={!!focusedInput}
          title="Select dates"
          hasDoneButton
          onClose={() => setFocusedInput(null)}
        >
          <Styled.SelectedDatesSection>
            <Styled.CalendarIcon name="calendar" color="foregroundSecondary" />
            <Styled.SelectedDate onClick={() => setFocusedInput(START_DATE)}>
              <Typography color="foregroundPrimary" variant="body/xs">
                Check-in
              </Typography>
              <Typography
                color={focusedInput === START_DATE ? "uiAccent" : "foregroundPrimary"}
                variant="body/sm-strong"
              >
                {checkInDate ?? "Select"}
              </Typography>
            </Styled.SelectedDate>
            <Styled.Hyphen>-</Styled.Hyphen>
            <Styled.SelectedDate onClick={() => setFocusedInput(END_DATE)}>
              <Typography color="foregroundPrimary" variant="body/xs">
                Checkout
              </Typography>
              <Typography
                color={focusedInput === END_DATE ? "uiAccent" : "foregroundPrimary"}
                variant="body/sm-strong"
              >
                {checkOutDate ?? "Select"}
              </Typography>
            </Styled.SelectedDate>
          </Styled.SelectedDatesSection>
          {mobileCalendar}
        </FullscreenModal>
      </>
    );
  }
  /* Custom rendering of calendar days, this logic matches  our legacy dashboard search datepicker and
    is needed to render the number of days tooltip. */
  const renderCalendarDay = (day) => {
    const onDayMouseEnter = (hoveredDay: Moment, e: MouseEvent<HTMLTableDataCellElement>) => {
      let nights = 0;

      if (values.checkIn) {
        nights = hoveredDay.diff(values.checkIn, "days");
      }

      if (nights > 0 && focusedInput === END_DATE && !isDateOutOfRange(hoveredDay)) {
        (e.target as HTMLTableDataCellElement).setAttribute(
          "data-number-of-nights",
          `${nights} night${nights > 1 ? "s" : ""}`
        );

        (e.target as HTMLTableDataCellElement).setAttribute("data-active-tooltip", "true");
      }

      return day.onDayMouseEnter(hoveredDay);
    };

    const onDayMouseLeave = (notHoveredDay: Moment, e: MouseEvent<HTMLTableDataCellElement>) => {
      (e.target as HTMLTableDataCellElement).setAttribute("data-active-tooltip", "false");

      return day.onDayMouseLeave(notHoveredDay);
    };

    return (
      <CalendarDay {...day} onDayMouseEnter={onDayMouseEnter} onDayMouseLeave={onDayMouseLeave} />
    );
  };

  return (
    <Styled.DateSelectorInput className={className} inputWidth={inputWidth} margin={margin}>
      <Styled.DateRangePicker checkInError={checkInError} checkOutError={checkOutError} size={size}>
        <DateRangePicker
          startDate={values.checkIn}
          startDateId="date-range-picker-start"
          startDatePlaceholderText="Check In"
          endDatePlaceholderText="Check Out"
          endDate={values.checkOut}
          endDateId="date-range-picker-end"
          renderCalendarDay={(day) => renderCalendarDay(day)}
          hideKeyboardShortcutsPanel
          isOutsideRange={isDateOutOfRange}
          displayFormat={dateRangePickerShortFormats[preferredDateFormat]}
          noBorder
          onDatesChange={({ startDate, endDate }) => {
            handleAmplitude?.(startDate?.toJSON(), endDate?.toJSON());
            calendarChangeHandler(startDate, endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={(focus: FocusedInput) => setFocusedInput(focus)}
          verticalSpacing={84}
          orientation={HORIZONTAL_ORIENTATION}
          customArrowIcon={<Styled.SeparatorArrow />}
          customInputIcon={<Icon name="calendar-day" color="foregroundSecondary" />}
        />
      </Styled.DateRangePicker>
    </Styled.DateSelectorInput>
  );
};
