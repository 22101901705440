import * as Styled from "./styles";
import Button from "@hotel-engine/common/Button";
import { openSalesForceChat } from "@hotel-engine/scripts/hooks";
import type {
  ISupportBillingInquiry,
  ISupportCase,
  ISupportCancelRequest,
  ISupportDateChange,
  ISupportNameChange,
  ISupportSpecialRequest,
  ILodgingBooking,
  BookingDetailsType,
  IFlightBooking,
  ICarBooking,
} from "@hotel-engine/types/supportCase";
import BookingDetails from "./components/BookingDetails";
import ActivityDetails from "./components/ActivityDetails";
import { getCaseTypeDetails } from "./helpers";
import CancelRequest from "./components/CancelRequest";
import type { ICaseTypes } from "./types";
import DateChange from "./components/DateChange";
import NameChange from "./components/NameChange";
import BillingInquiry from "./components/BillingInquiry";
import SpecialRequest from "./components/SpecialRequest";
import { ampli } from "ampli";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { useSingleSupportCaseQuery } from "@hotel-engine/react-query/supportCenter/useSingleSupportCaseQuery";
import { useEffect, useState } from "react";
import SupportCaseSpinner from "./components/SupportCaseSpinner";
import { SupportCaseDrawerActions } from "store/components/SupportCaseDrawer/SupportCaseDrawerRedux";
import { useExperiment } from "@hotel-engine/react-query/amplitude/useExperimentQuery";
import { initI18n } from "@hotel-engine/i18n";
import {
  type AlertSentiment,
  Box,
  Divider,
  Typography,
  Sheet,
  SheetContent,
  IconButton,
  SheetClose,
} from "@hotelengine/atlas-web";
import StatusCell from "pages/SupportCenter/components/SupportCenterTable/components/StatusCell";

export interface ISupportCaseDrawer {
  caseId: number | undefined;
  showDrawer: boolean;
}

initI18n();

const SupportCaseDrawer = ({ caseId, showDrawer }: ISupportCaseDrawer) => {
  const MIAWRollout = useExperiment("sf-miaw-rollout", { value: "off" });
  const sfMIAWFeatureFlagEnabled = MIAWRollout.data.value === "on";
  const user = useAppSelector((state) => state.Auth.user);
  const dispatch = useAppDispatch();

  const [fetchSingleSupportCase, setFetchSingleSupportCase] = useState(false);
  const [supportCase, setSupportCase] = useState<ISupportCase | undefined>(undefined);

  const isFlightBooking = supportCase?.bookingType === "flight";
  const isCarBooking = supportCase?.bookingType === "car_rental";

  // IGNORE-REASON ENS-2599 ⭐ fix me for good karma
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const singleSupportCaseQuery = useSingleSupportCaseQuery(caseId!, {
    enabled: fetchSingleSupportCase,
    onSuccess: () => {
      setFetchSingleSupportCase(false);
    },
    onError: () => {
      setFetchSingleSupportCase(false);
    },
  });

  useEffect(() => {
    if (caseId) {
      setFetchSingleSupportCase(true);
    }
  }, [caseId]);

  /**
   * open a support case on the drawer when clicked from notifications
   */

  useEffect(() => {
    const supportCaseFromNotification = singleSupportCaseQuery.data;
    if (!!supportCaseFromNotification) {
      setSupportCase(supportCaseFromNotification);

      dispatch(
        SupportCaseDrawerActions.setSupportCaseDrawerState({
          caseId,
          showDrawer: true,
        })
      );
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSingleSupportCase]);

  if (!supportCase) {
    return (
      <Sheet isOpen={showDrawer}>
        <SheetContent align="right">
          <SupportCaseSpinner />
        </SheetContent>
      </Sheet>
    );
  }

  const subType = supportCase.subType as ICaseTypes;

  const caseNumber = supportCase.caseNumber;

  const { alertBannerStatus, description, message } = getCaseTypeDetails(supportCase);

  const closeDrawer = () => {
    dispatch(
      SupportCaseDrawerActions.setSupportCaseDrawerState({
        caseId: undefined,
        showDrawer: false,
      })
    );
  };

  const salesForceChat = () => {
    ampli.clickCaseWannaTalkLink({ caseId: supportCase.id, userId: user?.id });
    openSalesForceChat();
  };

  const flightBookingData: BookingDetailsType = {
    data: supportCase.booking as IFlightBooking,
    isFlightBooking: true,
    isCarBooking: false,
  };

  const carBookingData: BookingDetailsType = {
    data: supportCase.booking as ICarBooking,
    isCarBooking: true,
    isFlightBooking: false,
  };
  const lodgingBookingData: BookingDetailsType = {
    data: supportCase.booking as ILodgingBooking,
    isFlightBooking: false,
    isCarBooking: false,
  };

  return (
    <Sheet isOpen={showDrawer} aria-expanded={showDrawer}>
      <SheetContent align="right" onInteractOutside={closeDrawer}>
        {singleSupportCaseQuery.isFetching ? (
          <SupportCaseSpinner />
        ) : (
          <>
            <Box display="flex" flexDirection="column" marginBottom={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={12}
              >
                <Typography variant="heading/xl" data-testid="support-case-drawer-subtype">
                  {subType}
                </Typography>
                <Box display="flex" alignItems="center">
                  <StatusCell supportCase={supportCase} />
                  <Box marginLeft={8}>
                    <SheetClose>
                      <IconButton
                        onClick={closeDrawer}
                        icon="xmark"
                        variant="plain"
                        shape="square"
                        ariaLabel="Go back"
                      />
                    </SheetClose>
                  </Box>
                </Box>
              </Box>
              {!!caseNumber && (
                <Typography variant="body/xs" color="foregroundSecondary">
                  CASE #{caseNumber}
                </Typography>
              )}
            </Box>
            {!isFlightBooking && !isCarBooking && (
              <Box marginBottom={24}>
                {(() => {
                  const renderedComponent = (() => {
                    switch (subType) {
                      case "Cancel Request":
                      case "Cancellation Request":
                        return (
                          <CancelRequest
                            details={supportCase.details as ISupportCancelRequest}
                            booking={supportCase.booking as ILodgingBooking}
                            bannerStatus={alertBannerStatus}
                          />
                        );
                      case "Date Change":
                        return <DateChange details={supportCase.details as ISupportDateChange} />;
                      case "Name Change":
                        return (
                          <NameChange
                            details={supportCase.details as ISupportNameChange}
                            booking={supportCase.booking as ILodgingBooking}
                          />
                        );
                      case "Billing Inquiry":
                        return (
                          <BillingInquiry
                            details={supportCase.details as ISupportBillingInquiry}
                            bannerStatus={alertBannerStatus}
                          />
                        );
                      case "Special Request":
                        return (
                          <SpecialRequest details={supportCase.details as ISupportSpecialRequest} />
                        );
                      default:
                        return null;
                    }
                  })();
                  return renderedComponent ? (
                    <>
                      {renderedComponent}
                      <Divider variant="dotted" color="dividerSecondary" />
                    </>
                  ) : (
                    <Divider variant="dotted" color="dividerSecondary" />
                  );
                })()}
              </Box>
            )}

            {!!message && (
              <Styled.SupportAlertBanner
                data-testid="alertBanner"
                sentiment={alertBannerStatus as AlertSentiment}
                title={message}
              >
                <span>{description}</span>
              </Styled.SupportAlertBanner>
            )}
            <ActivityDetails supportCase={supportCase} />

            <Box marginBottom={24}>
              <Divider variant="dotted" color="dividerSecondary" />
            </Box>

            {!!isFlightBooking ? (
              <BookingDetails booking={flightBookingData} closeDrawer={closeDrawer} />
            ) : !!isCarBooking ? (
              <BookingDetails booking={carBookingData} closeDrawer={closeDrawer} />
            ) : (
              <BookingDetails booking={lodgingBookingData} />
            )}

            {!sfMIAWFeatureFlagEnabled && (
              <>
                <Styled.FieldDivider />
                <Typography as="h2" variant="body/sm">
                  Want to talk?
                </Typography>

                <Button
                  className="size-adjustment"
                  type="link"
                  onClick={salesForceChat}
                  data-testid="support-case-drawer-live-chat"
                >
                  Live Chat
                </Button>
              </>
            )}
          </>
        )}
      </SheetContent>
    </Sheet>
  );
};

export default SupportCaseDrawer;
