import type { Dispatch, SetStateAction } from "react";
import { useEffect, useRef, useState } from "react";

import type {
  IGoogleMapsControlArgs,
  MapLocations,
  MapPosition,
} from "@hotel-engine/app/GoogleMap/types";
import GoogleMapsService from "@hotel-engine/services/GoogleMapsService";

import ExpandMapToggle from "./components/ExpandMapToggle";
import MapTypeToggle from "./components/MapTypeToggle";
import ZoomControl from "./components/ZoomControl";
import GoogleMapsSearchResultsUpdateSearchArea from "../GoogleSearchResultsMap/components/GoogleMapsSearchResultsUpdateSearchArea";
import { expandMap, isFullScreen } from "../../mapHelpers";
import * as Styled from "./styles";
import { useTrackMapZoomUpdate } from "./useTrackMapZoomUpdate";

export type GoogleMapsControlsProps = {
  /** Custom handler for map drag event, passing information on new map center */
  handleDragEnd?: (googleMapsControlArgs: IGoogleMapsControlArgs) => void;
  /** Custom handler for map expansion control */
  handleExpandMap?: () => void;
  /** Used for loading state on update map on move controls */
  isLoading?: boolean;
  /** Is the map expanded? Well? Is it?*/
  isMapExpanded?: boolean;
  /** Google map instance */
  map: google.maps.Map;
  /** Where in the app the map is, largely used for Amplitude tracking */
  mapLocation?: MapLocations;
  /** Used to set current map center on movements that don't trigger a new query */
  setCurrentMapCenter?: Dispatch<SetStateAction<MapPosition>>;
  /** Whether or not to show the map expand control */
  showExpand?: boolean;
  /** Whether or not to show the update map on move controls */
  showSearchControls?: boolean;
  /** Whether or not to show the type toggle (Basic/Satellite) */
  showType?: boolean;
  /** Whether or not to show the zoom in/zoom out controls */
  showZoom?: boolean;
};

const GoogleMapsControls = ({
  handleDragEnd,
  handleExpandMap,
  isLoading,
  isMapExpanded,
  map,
  mapLocation,
  setCurrentMapCenter,
  showExpand = true,
  showSearchControls = false,
  showType = true,
  showZoom = true,
}: GoogleMapsControlsProps) => {
  /** Used for Amplitude tracking */
  const [zoomControlClicked, setZoomControlClicked] = useState(false);

  /** Track in Amplitude when user updated the map zoom */
  useTrackMapZoomUpdate({
    map,
    isSearchResults: mapLocation === "searchResults",
    setZoomControlClicked,
    zoomControlClicked,
  });

  const googleMapsService = GoogleMapsService();
  const controlRef = useRef(null);

  useEffect(() => {
    const listeners = {
      zoom_changed: handleZoomChanged,
    };
    if (!!map) {
      googleMapsService.addListeners(map, listeners);
    }

    return () => {
      if (!!map) {
        googleMapsService.removeListeners(map, listeners);
      }
    };
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const handleZoomChanged = () => {
    /** Used to trigger an Amplitude zoom event that is not the result of using the zoom click control */
    setZoomControlClicked(false);
  };

  /** zoom handler to set passed zoom */
  const handleZoom = (val: number) => {
    map?.setZoom(val);

    setZoomControlClicked(true);
  };

  const handleShouldTriggerSearch = (controlArgs: IGoogleMapsControlArgs) => {
    setCurrentMapCenter?.(
      controlArgs.center?.toJSON() ?? {
        lat: 0,
        lng: 0,
      }
    );
    handleDragEnd?.(controlArgs);
  };
  const hasExpandOrType = showExpand || showType;
  const hasCustomExpandHandler = !!handleExpandMap;

  const showUpdateSearchAreaButton = !!showSearchControls;

  return (
    <div data-testid="google-map-controls">
      <Styled.ControlsWrapper ref={controlRef}>
        {!!hasExpandOrType && (
          <Styled.ButtonGroup $showUpdateSearchAreaButton={showUpdateSearchAreaButton}>
            {!!showExpand && (
              <ExpandMapToggle
                handleExpandMap={
                  hasCustomExpandHandler
                    ? handleExpandMap
                    : () => expandMap(map as google.maps.Map, controlRef.current)
                }
                isMapExpanded={isMapExpanded || isFullScreen()}
                mapLocation={mapLocation}
              />
            )}
            {!!showType && (
              <MapTypeToggle updateMapStyle={(mapType: string) => map?.setMapTypeId(mapType)} />
            )}
          </Styled.ButtonGroup>
        )}
        {!!showZoom && <ZoomControl handleZoom={handleZoom} get={(val) => map?.get(val)} />}
      </Styled.ControlsWrapper>
      {!!showUpdateSearchAreaButton && (
        <GoogleMapsSearchResultsUpdateSearchArea
          isLoading={!!isLoading}
          googleMapsService={googleMapsService}
          handleShouldTriggerSearch={handleShouldTriggerSearch}
          map={map}
        />
      )}
    </div>
  );
};

export default GoogleMapsControls;
