import config from "config";
import { Box, Button, Typography } from "@hotelengine/atlas-web";

export const MissingDetails = () => {
  const reloadPage = () => {
    globalThis.location.reload();
  };

  return (
    <Box
      backgroundColor="backgroundSecondary"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      gap={32}
      paddingTop={48}
      paddingLeft={32}
      paddingRight={32}
      style={{ height: "100vh", textAlign: "center" }}
    >
      <img
        src={`${config.cdnHost}/assets/trips/error-trips.svg`}
        alt="Issue fetching flight details"
        width={200}
      />
      <Box>
        <Typography variant="heading/md" color="foregroundPrimary" as="h4">
          There was an issue loading your details.
        </Typography>
        <Typography variant="body/sm" color="foregroundPrimary" as="p" marginBottom={0}>
          Rest assured, your booking is secure. Please try again later or contact our support team
          for assistance.
        </Typography>
      </Box>
      <Button
        variant="outlined"
        color="secondary"
        size="lg"
        onClick={reloadPage}
        style={{ width: "181px" }}
      >
        Try again
      </Button>
    </Box>
  );
};
