import styled, { css } from "styled-components";
import { flightsBreakpoints } from "@hotelengine/core-booking-web";
import { Select, Icon } from "@hotelengine/atlas-web";

import { searchHeight } from "@hotel-engine/constants";
import { colors } from "@hotel-engine/styles";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

interface IDateRangeProps {
  checkInError: boolean;
  checkOutError: boolean;
  emptyDates?: boolean;
}

interface IInputProps {
  inputWidth?: string;
  margin?: string;
}

// Using a color config for now until we complete building out our new theme
const dateColors = {
  primary: colors.retroBlue,
  hover: colors.denim,
  secondary: colors.white,
  secondaryHover: colors.grey[100],
  active: colors.placeboBlue,
  text: colors.black,
  contrastText: colors.white,
  error: colors.tomato,
  disabled: colors.grey[100],
  border: colors.grey[200],
  tooltip: colors.black,
} as const;

// This is to toggle between the atlas black and circled datepicker with the blue that follows flights and hotels
const atlasGuidelines = false;

const mobileDateSizes = {
  titleHeight: 56,
  dateSectionMargin: 12,
  selectedDatesHeight: 63,
  weekHeight: 40,
} as const;

export const DateSelectorInput = styled.div<IInputProps & { $isFlightsEnabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${() => {
    const breakpoint = `${flightsBreakpoints.lg}px`;

    return css`
      @media screen and (min-width: ${breakpoint}) {
        align-items: stretch;
    }`;
  }};
`;

export const DateSelectorIcon = styled(Icon)`
  font-size: 16px;
`;

export const SeparatorArrow = styled(Icon).attrs(() => ({
  name: "arrow-right",
}))`
  font-size: 11px;
  margin: 0 16px;

  // This should be removed when the location picker is changed into a floating container
  ${mediaQuery(
    "lg",
    "min"
  )(`
        margin: 0;
    `)}
`;

export const DateCalendar = styled.div<{ isFocused?: boolean }>`
  ${({ isFocused }) =>
    isFocused &&
    css`
      // subtract margin + height of top section
      height: calc(
        100% - ${mobileDateSizes.selectedDatesHeight}px -
        ${mobileDateSizes.dateSectionMargin}px
      );
    `}
  .DayPicker_weekHeader {
    height: ${mobileDateSizes.weekHeight}px;
    border-top: 1px solid ${({ theme }) => theme.colors.borderSubtle};

    ${mediaQuery("xl")(`
                top: 55px;
                height: unset;
                border: none;
            `)}
  }

  ${mediaQuery(
    "xl",
    "max"
  )(`
        .DayPicker_weekHeader_ul {
            line-height: ${mobileDateSizes.weekHeight - 2}px;
            margin: 0;
        }

    `)}
  .CalendarMonth_caption {
    color: ${({ theme }) => theme.colors.foregroundPrimary};
    font-size: 16px;
  }

  .DayPickerNavigation_button__default {
    border: none;
  }

  .DateRangePicker_picker__directionLeft {
    transform: translate(-25px, -${searchHeight});
    border-radius: ${({ theme }) => theme.borderRadius[24]};
    top: 119px !important;
  }

  .DayPicker_transitionContainer {
    border-radius: ${({ theme }) => theme.borderRadius[24]};
    ${mediaQuery(
      "xl",
      "max"
    )(`
            padding-top: 53px;

        `)}
  }

  .DayPicker__withBorder {
    border-radius: ${({ theme }) => theme.borderRadius[24]};
    box-shadow: 0px 8px 24px 1px rgba(0, 0, 0, 0.12),
    0px 1px 4px rgba(0, 0, 0, 0.04);
  }

  .CalendarMonth_table {
    border-collapse: unset;
  }

  // Style the date picker days

  .CalendarDay {
    border: 1px solid transparent;
    position: relative;

    &.CalendarDay__default {
      background: ${({ theme }) => theme.colors.backgroundPrimary};

      &:hover,
      &:focus {
        background: ${({ theme }) =>
          atlasGuidelines ? "transaprent" : theme.colors.backgroundSelectedSubtle};
        border: 1px solid ${({ theme }) =>
          atlasGuidelines ? theme.colors.uiPrimary : dateColors.primary};

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          border-radius: ${({ theme }) => (atlasGuidelines ? theme.borderRadius.full : "0")};
        }
      }
    }

    // The two selected days

    &.CalendarDay__selected {
      background: ${({ theme }) => (atlasGuidelines ? theme.colors.uiPrimary : dateColors.primary)};
      border-radius: ${({ theme }) => (atlasGuidelines ? theme.borderRadius.full : "0")};
      color: ${({ theme }) => theme.colors.backgroundPrimary};
      position: relative;

      &:hover,
      &:focus {
        background: ${({ theme }) =>
          atlasGuidelines ? theme.colors.uiPrimaryActive : colors.bavarianBlue};
      }
    }

    // The selected range between check in and check out

    &.CalendarDay__selected_span {
      background: ${({ theme }) =>
        atlasGuidelines ? theme.colors.backgroundSecondary : theme.colors.backgroundSelectedSubtle};
      color: ${({ theme }) => theme.colors.foregroundPrimary};

      &:hover,
      &:focus {
        background: ${({ theme }) =>
          atlasGuidelines
            ? theme.colors.backgroundSecondary
            : theme.colors.backgroundSelectedSubtle};
      }
    }

    // Selected check in

    &.CalendarDay__selected_start {
      border-radius: ${({ theme: { borderRadius } }) =>
        atlasGuidelines ? borderRadius.full : `${borderRadius.sm} 0 0 ${borderRadius.sm}`};
    }


    // Selected check out

    &.CalendarDay__selected_end:not(.CalendarDay__selected_start) {
      border-radius: ${({ theme: { borderRadius } }) =>
        atlasGuidelines ? borderRadius.full : `0 ${borderRadius.sm} ${borderRadius.sm} 0`};
    }

    // Selected check out

    &.CalendarDay__selected_end.CalendarDay__selected_start {
      border-radius: ${({ theme: { borderRadius } }) =>
        atlasGuidelines ? borderRadius.full : borderRadius.sm}
    }
  ;

    // The non-finalized selected range between check in and check out

    &.CalendarDay__hovered_span {
      background: ${({ theme }) =>
        atlasGuidelines ? theme.colors.backgroundSecondary : theme.colors.backgroundSelectedSubtle};
      color: ${({ theme }) => theme.colors.foregroundPrimary};

      &:hover,
      &:focus {
        background: ${({ theme }) =>
          atlasGuidelines
            ? theme.colors.backgroundSecondary
            : theme.colors.backgroundSelectedSubtle};
        border: 1px solid ${({ theme }) =>
          atlasGuidelines ? theme.colors.uiPrimary : dateColors.primary};
        border-radius: ${({ theme: { borderRadius } }) =>
          `0 ${borderRadius.sm} ${borderRadius.sm} 0`};

      }
    }

    // Build the tooltip when hovering over a date

    &[data-active-tooltip="true"] {
      position: relative;

      &:hover::before {
        position: absolute;
        width: 75px;
        top: -36px;
        left: 50%;
        transform: translateX(-50%);
        content: attr(data-number-of-nights);
        background-color: ${({ theme }) => theme.colors.foregroundPrimary};
        color: ${({ theme }) => theme.colors.backgroundPrimary};
        z-index: 100 /* disable theming (do not replicate) */;
        font-size: ${({ theme }) => theme.typography.desktop["body/xs"].fontSize};
        padding: ${({ theme }) => theme.spacing[8]};
        border-radius: ${({ theme }) => theme.borderRadius.sm};
      }

      &:hover::after {
        position: absolute;
        top: -3px;
        transform: translateX(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid ${({ theme }) => theme.colors.foregroundPrimary};
        content: " ";
        font-size: 0;
        line-height: 0;
        margin-left: -25%;
        width: 0;
      }
    }
  }

  // Month title

  .CalendarMonth_caption {
    color: ${({ theme }) => theme.colors.foregroundPrimary};
    font-size: ${({ theme }) => theme.typography.desktop["body/md"].fontSize};
    padding-top: 13px;

    strong {
      font-weight: normal;
    }
  }

  .CalendarMonthGrid_month__hidden {
    visibility: visible;
  }
`;

export const DateRangePicker = styled(DateCalendar)<IDateRangeProps>`
  height: 100%;

  &:has(.DateInput_input[id="date-range-picker-start"]:hover), &:has(.DateInput_input[id="date-range-picker-start"]:focus) {
    /** fixes the gap caused by the transparent border-right/left on the date-range-picker-start and date-range-picker-end */

    .DateInput_input[id="date-range-picker-start"] {
      z-index: ${({ theme }) => theme.zIndex.backdropAbove + theme.zIndex.backdropAbove};
    }

    .DateRangePickerInput_calendarIcon {
      z-index: ${({ theme }) =>
        theme.zIndex.backdropAbove + theme.zIndex.backdropAbove + theme.zIndex.backdropAbove};
    }
  }

  .DateRangePicker {
    &,
    > div {
      height: 100%;
    }
  }

  .DateRangePickerInput {
    display: flex;
    align-items: stretch;
    height: 50px;
    background-color: transparent;

    .DateRangePickerInput_arrow {
      /** fixes the gap caused by the transparent border-right/left on the date-range-picker-start and date-range-picker-end */
      z-index: ${({ theme }) => theme.zIndex.backdropAbove};
    }
  }

  .DateInput_fang {
    display: none;
  }

  .DateInput {
    background-color: transparent;
    width: ${({ emptyDates }) => (emptyDates ? "unset" : "22ch")};

    .DateInput_input {
      color: ${({ theme }) => theme.colors.foregroundPrimary};
      font-weight: ${({ theme }) => theme.typography.desktop["body/md"].fontWeight};
      font-size: ${({ theme }) => theme.typography.desktop["body/md"].fontSize};
      margin-bottom: 0;
      text-align: left;
      line-height: unset;
      cursor: pointer;
      height: 100%;
      border-radius: ${({ theme }) => theme.borderRadius.nsActionMd};
      border: 1px solid ${({ theme }) => theme.colors.inputBorder};
      padding: 0;

      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.uiPrimary};
      }
      &:focus {
        box-shadow: 0 0 0 1px white, 0 0 0 2px ${({ theme }) => theme.colors.uiPrimary};
      }
    }

    .DateInput_input[id="date-range-picker-start"] {
      min-width: 100px;
      padding-left: 0;

      ${({ theme, emptyDates }) =>
        css`
          border-right: ${emptyDates ? `1px solid ${theme.colors.uiPrimary}` : "none"};
          border-top-right-radius: ${emptyDates ? theme.borderRadius.full : theme.borderRadius.none};
          border-bottom-right-radius: ${
            emptyDates ? theme.borderRadius.full : theme.borderRadius.none
          };
          padding-left: 36px;
          /** fixes the gap caused by the transparent border-right/left on the date-range-picker-start and date-range-picker-end */
          position: relative;
          right: -1px;

          &:not(:hover, :focus) {
            border-right: 1px solid ${emptyDates ? theme.colors.borderDefault : "transparent"} !important;
          }

          &:focus,
          &:hover {
            border-right: 1px solid ${theme.colors.uiPrimary};
            z-index: ${theme.zIndex.backdropAbove};
          }
        `}
    }

    .DateInput_input[id="date-range-picker-end"] {
      padding-left: 8px;

      ${({ theme }) =>
        css`
          border-left: none;
          border-top-left-radius: ${theme.borderRadius.none};
          border-bottom-left-radius: ${theme.borderRadius.none};
          /** fixes the gap caused by the transparent border-right/left on the date-range-picker-start and date-range-picker-end */
          position: relative;
          left: -1px;

          &:not(:hover, :focus) {
            border-left: 1px solid transparent !important;
          }

          &:focus,
          &:hover {
            border-left: 1px solid ${theme.colors.uiPrimary};
            z-index: ${theme.zIndex.backdropAbove};
          }
        `}
    }

    &.DateInput_1:last-child {
      ${({ emptyDates }) =>
        emptyDates
          ? css`visibility: hidden;
        width: 0`
          : ""}
      width: ${({ emptyDates }) => (emptyDates ? "unset" : "20ch")};

    }
  }

  .DateRangePickerInput_calendarIcon {
    display: flex;
    align-items: center;
    margin: 0;
    border: 1px solid transparent;
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    width: 40px;
    height: 100%;
    align-self: center;

    position: absolute;
    z-index: ${({ theme }) => theme.zIndex.backdropAbove};
    top: 0;
    left: 4px;

    &:hover,
    &:focus {
      border: none;
    }
  }

  ${({ checkInError }) =>
    checkInError &&
    css`
      .DateInput_input[id="date-range-picker-start"] {
        color: ${dateColors.error};

        &::placeholder {
          color: ${dateColors.error};
          opacity: 1; // Firefox
        }

        &:hover,
        &:focus {
          border-color: ${dateColors.error};
        }
      }
    `}
  ${({ checkOutError }) =>
    checkOutError &&
    css`
      .DateInput_input[id="date-range-picker-end"] {
        color: ${dateColors.error};

        &::placeholder {
          color: ${dateColors.error};
          opacity: 1; // Firefox
        }

        &:hover,
        &:focus {
          border-color: ${dateColors.error};
        }
      }
    `}
  .DateRangePickerInput_arrow {
    display: flex;
    align-items: center;
    color: inherit;

    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    border-top: 1px solid ${({ theme }) => theme.colors.inputBorder};
    border-bottom: 1px solid ${({ theme }) => theme.colors.inputBorder};
  }

  .DayPickerNavigation_button {
    &:hover,
    &:focus {
      background: ${colors.grey[50]};
    }
  }

  .DayPickerNavigation_button__horizontalDefault {
    padding: 0 9px;
    top: 16px;
  }
`;

export const Dates = styled.div`
  margin: auto;
`;

export const BottomPanel = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.spacing[24]} 0`};
  justify-content: space-around;
  border-top: solid 1px ${({ theme }) => theme.colors.inputBorder};
  font-size: 16px;
`;

export const PanelColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing[20]}`};

  & > *{
    margin-top: ${({ theme }) => theme.spacing[4]};
  }
`;

export const Title = styled.div`
  font-weight: bold;
`;

export const SelectMenu = styled(Select)`
  width: 100%;
`;
