import { Link } from "@hotel-engine/lib/react-router-dom";

import { Box, BrandLogo, Typography } from "@hotelengine/atlas-web";
import { routes } from "@hotel-engine/constants";

import { useUser } from "@hotel-engine/hooks";
import * as Styled from "./styles";
import { User } from "@hotel-engine/types/user";

const height = 32;

export function Logo() {
  const user = useUser();
  return (
    <Link to={routes.dashboard} style={{ height }}>
      {User.isPersonalTravel(user) ? (
        <BrandLogo name="engine--lockup" size={height} />
      ) : typeof user.business.logoUrl === "string" ? (
        <Styled.Logo src={user.business.logoUrl} alt={user.business.name} size={height} />
      ) : (
        <Box display="flex" alignItems="center" gap={8}>
          <BrandLogo name="engine" size={height} />
          <Typography
            variant="body/sm"
            color="foregroundPrimary"
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "block",
              whiteSpace: "nowrap",
              maxWidth: "240px",
            }}
          >
            {user.business.name}
          </Typography>
        </Box>
      )}
    </Link>
  );
}
