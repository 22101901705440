import { useEffect, useRef } from "react";
import { ampli } from "ampli";

import type { IFlightItinerary } from "@hotel-engine/types/itinerary";
import { useLocation } from "@hotel-engine/lib/react-router-dom";
import { Box } from "@hotelengine/atlas-web";

import type { IFlightItineraryWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/flights";
import type { ITripPreview } from "pages/Trips/context";

import { ModificationsProvider } from "../ModificationsContext";
import FlightItineraryContentBody from "./components/FlightItineraryContentBody";
import CancelledBanner from "../shared/CancelledBanner";
import BookingSuccess from "../shared/BookingSuccess";
import HeaderActions from "../shared/HeaderActions";
import type { ILocationState } from "..";
import { Loader } from "../styles";

export interface IFlightItineraryContentProps {
  /** Used to manage location specific logic depending on whether the user is on the Trips page or Itinerary page */
  isPreview: boolean;
  /** The reservation being displayed */
  itinerary: IFlightItinerary | IFlightItineraryWithId;
  /** Used to refetch the reservation after modifications are made within the panel */
  refetchReservation: () => Promise<void> | void;
  /** Used to render loader while full reservation is being fetched on the Trips page */
  isLoading?: boolean;
  /** Only used when on Trips page preview panel close button is pressed */
  onClose?: (tripPreview: ITripPreview) => void;
}

const FlightItineraryContent = ({
  isPreview,
  itinerary,
  refetchReservation,
  isLoading,
  onClose,
}: IFlightItineraryContentProps) => {
  const startTime = useRef(Date.now());
  const initialMount = useRef(true);

  const pendingCancellationCaseStatuses = ["NEW", "PENDING"];
  const isPendingCancellation = pendingCancellationCaseStatuses.includes(
    String(itinerary.pendingModifications?.cancellation)
  );
  const showCancellationBanner = !!itinerary.isFullyCancelled || !!isPendingCancellation;
  const { state: locationState } = useLocation<ILocationState>();

  const shouldShowBookingConfirmation = !showCancellationBanner && !!locationState?.confirmation;
  const showLoader = isPreview && isLoading;

  useEffect(() => {
    if (itinerary && initialMount.current) {
      const loadingTime = Date.now() - startTime.current;
      ampli.viewItinerary({
        bookingId: itinerary.bookingNumber,
        bookingType: "flight",
        subdomain: globalThis?.location.host,
        viewUrl: globalThis?.location.href,
        loadingTime,
      });
      initialMount.current = false;
    }
  }, [itinerary, itinerary?.bookingNumber]);

  return (
    <ModificationsProvider
      reservation={itinerary}
      isPreview={isPreview}
      refetchReservation={refetchReservation}
    >
      {!!showLoader ? (
        <Box backgroundColor="backgroundSecondary" style={{ height: "100vh" }}>
          <Loader data-testid="itin-content-loader" size="large" />
        </Box>
      ) : (
        <>
          {!!showCancellationBanner && (
            <CancelledBanner
              isLodging={false}
              isPendingCancellation={!!(isPendingCancellation && !itinerary.isFullyCancelled)}
              isRefresh
            />
          )}
          <HeaderActions
            onClose={onClose}
            itinerary={isPreview ? (itinerary as IFlightItineraryWithId) : undefined}
          />
          {!!shouldShowBookingConfirmation && (
            <Box display="flex" alignItems="center">
              <BookingSuccess propertyConfirmationNumber={itinerary.bookingNumber} />
            </Box>
          )}
          <FlightItineraryContentBody />
        </>
      )}
    </ModificationsProvider>
  );
};

export default FlightItineraryContent;
