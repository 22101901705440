import AlertBanner from "@hotel-engine/common/AlertBanner";
import { useSystemOutages } from "@hotel-engine/hooks/useSystemOutages";

const onFailure = {
  title: "",
  body: "",
};

const SystemOutageBanner = () => {
  const { payload } = useSystemOutages(onFailure);
  const { title, body } = payload;

  if (!title || !body) {
    return null;
  }

  return (
    <AlertBanner
      message={title}
      description={<p className="body-text">{body}</p>}
      variant="system-outage"
      closable
      banner
      customIcon={"exclamation-triangle"}
      showIcon
      type={"error"}
      outage
    />
  );
};

export { SystemOutageBanner };
