import { useBaseModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import {
  CANCELLATION_SUCCESS_SUBTITLE,
  CAR_SUCCESS_SUBTITLE,
  CAR_SUCCESS_TITLE,
  FLIGHT_REQUEST_SUCCESS_SUBTITLE,
  FLIGHT_REQUEST_SUCCESS_TITLE,
  FLIGHT_SUCCESS_SUBTITLE,
  FLIGHT_SUCCESS_TITLE,
  MULTI_ONE_ROOM_SUCCESS_TITLE,
  MULTI_ROOM_SUCCESS_TITLE,
  SINGLE_ROOM_SUCCESS_TITLE,
} from "../CancelRouter/constants";
import { isCar, isFlight, isLodging } from "../../../isLodging";

export const useStatusModalCopy = () => {
  const {
    state: { modificationInfo },
    reservation,
  } = useBaseModificationsContext();

  let subtitle: string | undefined;
  let title: string | undefined;

  const isFlightItinerary = isFlight(reservation);
  const isLodgingItinerary = isLodging(reservation);
  const isCarItinerary = isCar(reservation);

  const isFlightCancellationRequest =
    !modificationInfo.refundAmount || !modificationInfo.refundType;

  if (isFlightItinerary) {
    title = isFlightCancellationRequest ? FLIGHT_REQUEST_SUCCESS_TITLE : FLIGHT_SUCCESS_TITLE;
    subtitle = isFlightCancellationRequest
      ? FLIGHT_REQUEST_SUCCESS_SUBTITLE
      : FLIGHT_SUCCESS_SUBTITLE;
  }

  if (isCarItinerary) {
    title = CAR_SUCCESS_TITLE;
    subtitle = CAR_SUCCESS_SUBTITLE;
  }

  if (isLodgingItinerary) {
    if (modificationInfo.modificationType === "cancel") {
      subtitle = CANCELLATION_SUCCESS_SUBTITLE;

      if (!!modificationInfo.roomsModified && modificationInfo.roomsModified > 1) {
        title = MULTI_ROOM_SUCCESS_TITLE;
      } else {
        title =
          reservation.roomCount === 1 ? SINGLE_ROOM_SUCCESS_TITLE : MULTI_ONE_ROOM_SUCCESS_TITLE;
      }
    }
  }

  return {
    title,
    subtitle,
  };
};
