import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import { formatCurrency } from "@hotel-engine/utilities";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";

import { FlightRefundType } from "../../../../hooks/usePreviewFlightRefund";
import * as Styled from "../../styles";

export interface IRefundInfoProps {
  isLoading: boolean;
  refundInfo: {
    refundAmount?: string;
    refundType: FlightRefundType;
  } | null;
}

const RefundInfo = ({ isLoading, refundInfo }: IRefundInfoProps) => {
  const { typographyVariants } = useTypographyScaling();

  const formattedAmount = formatCurrency(refundInfo?.refundAmount || 0);

  const getContent = () => {
    if (isLoading) {
      return (
        <Icon
          name="spinner-third"
          animationStyle="spin"
          data-testid="loader"
          color="foregroundPrimary"
        />
      );
    } else if (!refundInfo) {
      return (
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          Requesting cancellation will notify our support team to reach out within 24 hours.
        </Typography>
      );
    } else if (refundInfo?.refundType === FlightRefundType.Nonrefundable) {
      return (
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          This is a non-refundable flight.
        </Typography>
      );
    } else if (refundInfo?.refundType === FlightRefundType.Unknown) {
      return (
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          You will get a confirmation email with the refund amount and method of payment.
        </Typography>
      );
    } else {
      return (
        <Styled.InfoContainer>
          <Styled.ListItem>
            <Typography variant={typographyVariants.body} color="foregroundPrimary">
              Refund method
            </Typography>
            <Typography variant={typographyVariants.body} color="foregroundPrimary">
              {refundInfo?.refundType}
            </Typography>
          </Styled.ListItem>
          <Styled.ListItem>
            <Typography variant={typographyVariants.bodyStrong} color="foregroundPrimary">
              Total refund
            </Typography>
            <Typography variant={typographyVariants.bodyStrong} color="foregroundPrimary">
              {formattedAmount}
            </Typography>
          </Styled.ListItem>
        </Styled.InfoContainer>
      );
    }
  };

  return (
    <Box
      display="flex"
      justifyContent={isLoading || !refundInfo ? "center" : "flex-start"}
      alignItems="center"
      marginTop={24}
    >
      {getContent()}
    </Box>
  );
};

export default RefundInfo;
