import { MAX_MAP_ZOOM, MIN_MAP_ZOOM } from "@hotel-engine/app/GoogleMap/constants";
import { useState } from "react";
import * as Styled from "../../../../styles";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { Divider } from "@hotelengine/atlas-web";

function returnIsMaxDisabled(current: number, max: number) {
  return current >= max;
}

function returnIsMinDisabled(current: number, min: number) {
  return current <= min;
}

type ZoomControlProps = {
  handleZoom: (val: number) => void;
  get: (key: string) => number;
};

const ZoomControl = ({ handleZoom, get }: ZoomControlProps) => {
  const rebrandFlagOn = useIsFeatureFlagOn("rebrand_logo_copy");
  const [{ zoomInDisabled, zoomOutDisabled }, setDisabledState] = useState({
    zoomInDisabled: false,
    zoomOutDisabled: false,
  });

  function returnDisabledState(updatedZoom: number) {
    return {
      zoomOutDisabled: returnIsMinDisabled(updatedZoom, get("minZoom") || MIN_MAP_ZOOM),
      zoomInDisabled: returnIsMaxDisabled(updatedZoom, get("maxZoom") || MAX_MAP_ZOOM),
    };
  }

  const zoomOut = () => {
    const currentZoom = get("zoom");
    const updatedZoom = currentZoom - 1;
    setDisabledState(returnDisabledState(updatedZoom));
    handleZoom(get("zoom") - 1);
  };

  const zoomIn = () => {
    const currentZoom = get("zoom");
    const updatedZoom = currentZoom + 1;
    setDisabledState(returnDisabledState(updatedZoom));
    handleZoom(updatedZoom);
  };

  if (rebrandFlagOn) {
    return (
      <Styled.ZoomControl data-testid="zoom-control">
        <Styled.GoogleMapControlIconButtonRebrand
          aria-label={"zoom in"}
          data-testid="zoom-control-in"
          icon="plus"
          isDisabled={zoomInDisabled}
          onClick={zoomIn}
        />
        <Divider />
        <Styled.GoogleMapControlIconButtonRebrand
          aria-label={"zoom out"}
          data-testid="zoom-control-out"
          icon="minus"
          isDisabled={zoomOutDisabled}
          onClick={zoomOut}
        />
      </Styled.ZoomControl>
    );
  }

  return (
    <Styled.ZoomControl data-testid="zoom-control">
      <Styled.GoogleMapControlIconButton
        aria-label={"zoom in"}
        data-testid="zoom-control-in"
        icon="plus"
        isDisabled={zoomInDisabled}
        onClick={zoomIn}
      />
      <Styled.GoogleMapControlIconButton
        aria-label={"zoom out"}
        data-testid="zoom-control-out"
        icon="minus"
        isDisabled={zoomOutDisabled}
        onClick={zoomOut}
      />
    </Styled.ZoomControl>
  );
};
export default ZoomControl;
