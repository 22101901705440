import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const OptionContainer = styled.div<{ $rebrand: boolean }>`
  ${flexbox({ alignItems: "center" })}
  ${({ $rebrand }) =>
    $rebrand
      ? `
      box-sizing: border-box;
      color: inherit;`
      : `
      height: 36px;
      padding: 10px;
    `}
`;

export const OptionIcon = styled.div<{ $rebrand: boolean }>`
  ${({ $rebrand }) =>
    $rebrand
      ? `
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: ${({ theme }) => theme.borderRadius[16]};
      background-color: ${({ theme }) => theme.colors.backgroundSecondary};
      min-height: 40px;
      min-width: 40px;
      `
      : `
      ${flexbox({ justifyContent: "center", alignItems: "center" })}
      padding: 10px;
      min-width: 35px;
    `}


`;

export const OptionDescription = styled.div`
  padding: 10px;
  &:hover {
    overflow-x: auto;
  }
`;

export const RightIcon = styled.span`
  padding-right: ${({ theme }) => theme.spacing[8]};
  display: flex;
  align-items: stretch;
`;
