import { useEffect } from "react";

import { Box, Typography } from "@hotelengine/atlas-web";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { Modal } from "@hotel-engine/common/Modal";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

import type { IFlightItineraryWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/flights";

import CancellationTitle from "../common/CancellationTitle";
import CancellationButton from "../common/CancellationButton";
import CancelConfirmationModal from "../common/CancelConfirmationModal";
import { useCancelFlight } from "../../hooks/useCancelFlight";
import { usePreviewFlightRefund } from "../../hooks/usePreviewFlightRefund";
import FlightInformation from "./components/FlightInformation";
import RefundInfo from "./components/RefundInfo";
import * as Styled from "./styles";

const FlightCancellation = () => {
  const { COMPANY_NAME } = useCompanyName();
  const { typographyVariants } = useTypographyScaling();
  const {
    isPreview,
    state: { showConfirmationModal },
    reservation,
    dispatch,
  } = useFlightModificationsContext();

  const {
    refundInfo,
    isLoading,
    isError: isRefundCalcError,
  } = usePreviewFlightRefund(
    (reservation as IFlightItineraryWithId).tripId,
    (reservation as IFlightItineraryWithId).bookingNumber
  );

  const cancelRefundAmount = refundInfo?.refundAmount;
  const cancelRefundType = refundInfo?.refundType;

  const cancellationButtonText = !!refundInfo ? "Cancel flight" : "Request cancellation";

  useEffect(() => {
    if (isRefundCalcError) {
      Modal.info({
        title: "We couldn't process your request.",
        onOk: () => globalThis.history.go(0),
        content: `There was an issue processing your cancellation. Please contact ${COMPANY_NAME} support at at 855-567-4683.`,
      });
    }
  }, [COMPANY_NAME, isRefundCalcError]);

  const setShowStatusModal = () => {
    dispatch({
      type: "SET_SHOW_STATUS_MODAL",
      payload: true,
    });
  };

  const { submitCancelFlight } = useCancelFlight({
    reservation: reservation as IFlightItineraryWithId,
    showStatusModal: setShowStatusModal,
    refundInfo: {
      totalRefundAmount: Number(cancelRefundAmount),
      refundType: cancelRefundType,
    },
    dispatch,
  });

  return (
    <Box backgroundColor="backgroundSecondary">
      <CancellationTitle isFlight />
      <Typography
        variant={typographyVariants.body}
        color="foregroundPrimary"
        as="p"
        style={{ textAlign: "center", marginBottom: 0 }}
      >
        All passengers and flights will be cancelled from this booking. Cancellations cannot be
        reversed.
      </Typography>
      <FlightInformation />
      <Styled.StickyContainer $isPreview={isPreview}>
        <RefundInfo isLoading={isLoading} refundInfo={refundInfo} />
        <CancellationButton text={cancellationButtonText} isRefundCalcLoading={isLoading} />
      </Styled.StickyContainer>
      {!!showConfirmationModal && (
        <CancelConfirmationModal
          onConfirm={submitCancelFlight}
          isNonAPIRequest={!refundInfo}
          isFlight
        />
      )}
    </Box>
  );
};

export default FlightCancellation;
