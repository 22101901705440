import moment from "moment-timezone";

import type { IDateFormat } from "@hotel-engine/types/business";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import { formatDate } from "../formatters/formatDates";
import { getAbbreviatedTimezone } from "./getAbbreviatedTimezone";

export const getCancelDateInfo = ({
  flexEnabled,
  cancelBy,
  propertyTimezone,
  checkIn,
  preferredDateFormat,
  flexProEnrolled,
  flexCutoffTime,
}: Partial<IReservationBase> & {
  preferredDateFormat: IDateFormat;
  flexProEnrolled?: boolean;
  flexCutoffTime?: number;
}) => {
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userTimezone = currentTimezone === "UTC" ? undefined : currentTimezone;

  // ensure property timezone is utc if it's null on property
  if (!propertyTimezone) propertyTimezone = "UTC";

  const displayTimezone = userTimezone || propertyTimezone;

  // get formatted timezone
  const abbreviatedTimezone = flexEnabled
    ? getAbbreviatedTimezone({
        timezoneDatabaseName: displayTimezone,
        date: checkIn,
      })
    : getAbbreviatedTimezone({
        timezoneDatabaseName: displayTimezone,
        date: cancelBy,
      });

  // for flexpro bookings we want to display the cancel date as the latest option between
  // noon on day of checkin or the cancelby of the room
  const noonOnDayOfCheckInPropertyTimezone = moment(checkIn)
    .tz(propertyTimezone)
    .set({ hour: 12, minute: 0, second: 0 });

  const cancelByIsLaterThanNoon =
    cancelBy && noonOnDayOfCheckInPropertyTimezone.isBefore(moment(cancelBy).tz(propertyTimezone));

  let cancellationTime: number | string;

  // the last time to cancel whichever is later between 12pm on the day of check in or the room's cancellation policy
  if (flexProEnrolled && cancelByIsLaterThanNoon) {
    cancellationTime = moment(cancelBy).tz(displayTimezone).format("h:ss A");
  } else {
    cancellationTime = noonOnDayOfCheckInPropertyTimezone.tz(displayTimezone).format("h:ss A");
  }

  // get the date for the copy
  let formattedDate = flexEnabled ? moment(checkIn) : moment(cancelBy);
  formattedDate = formattedDate.utc().tz(displayTimezone, flexEnabled);

  if (flexEnabled) {
    return {
      refundableString: `${cancellationTime} ${abbreviatedTimezone} on ${formatDate(
        formattedDate,
        "MMM D YYYY",
        preferredDateFormat
      )}`,
      cancelMessage: `Cancel by ${cancellationTime} ${abbreviatedTimezone} on ${formatDate(
        formattedDate,
        "MMM D YYYY",
        preferredDateFormat
      )}`,
      cancelBannerMessage: `Cancellation available before ${cancellationTime} (${abbreviatedTimezone}), ${formatDate(
        formattedDate,
        "MMM D",
        preferredDateFormat
      )}.`,
      refundPolicy: `${formatDate(
        formattedDate,
        "MMM D",
        preferredDateFormat
      )} at ${cancellationTime} ${abbreviatedTimezone}.`,
    };
  }

  const cancelByTime = moment(cancelBy);
  let time;
  if (flexCutoffTime !== undefined) {
    time = cancelByTime.hours(flexCutoffTime).format("ha");
  } else {
    time = cancelByTime.tz(displayTimezone).format("h:mma");
  }

  return {
    refundableString: `${time} ${abbreviatedTimezone} on ${formatDate(
      formattedDate,
      "MMM D YYYY",
      preferredDateFormat
    )}`,
    cancelByString: `${formatDate(
      moment(cancelBy).tz(displayTimezone),
      "MMM D",
      preferredDateFormat
    )} at ${time} ${abbreviatedTimezone}`,
    cancelUntilString: formatDate(
      moment(cancelBy).tz(displayTimezone),
      "MMM D",
      preferredDateFormat
    ),
    cancelMessage: `Refundable before ${time} (${abbreviatedTimezone}) ${formatDate(
      formattedDate,
      "MMM D",
      preferredDateFormat,
      true
    )}`,
    cancelBannerMessage: `Cancellation refundable before ${time} (${abbreviatedTimezone}), ${formatDate(
      formattedDate,
      "MMM D",
      preferredDateFormat
    )}.`,
    refundPolicy: `${formatDate(
      formattedDate,
      "MMM D",
      preferredDateFormat
    )} at ${time} ${abbreviatedTimezone}.`,
  };
};
