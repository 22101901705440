import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { TNotificationCategory } from "@hotel-engine/types/courierNotifications";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";
import type React from "react";

export const Notification = styled.li`
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[16]}`};
  width: 100%;
  ${flexbox({
    alignItems: "flex-start",
    direction: "row",
    justifyContent: "space-between",
  })};
  margin-bottom: ${({ theme }) => theme.spacing[8]};

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundPrimaryHover};
    cursor: pointer;
  }

  &:last-of-type {
    margin-bottom: ${({ theme }) => theme.spacing[0]};
  }
`;

export const NotificationIconWrap = styled.figure<{
  category?: TNotificationCategory;
  $isRedesign24?: boolean;
}>`
  color: ${({ theme }) => theme.colors.foregroundInverse};
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  ${flexbox({ alignItems: "center", justifyContent: "center" })}
  position: relative;
  text-align: center;

  background: ${({ category, theme, $isRedesign24 }) => {
    // TODO: once fully migrated to redesign24, remove this logic branch and variable $isRedesign24
    if ($isRedesign24) {
      switch (category) {
        case "property_reviews":
          return theme.colors.accentBlueLightest;
        case "rewards":
        case "promotions_and_special_offers":
          return theme.colors.accentTealLightest;
        case "industry_news_and_updates":
          return theme.colors.accentYellowLightest;
        case "new_features":
        case "product_updates":
          return theme.colors.accentPurpleLightest;
        case "policy_updates":
        case "account_updates":
          return theme.colors.accentRedLightest;
        default:
          return theme.colors.accentGreenLightest;
      }
    } else {
      switch (category) {
        case "property_reviews":
          return theme.colors.accentOrangeLight;
        case "rewards":
          return theme.colors.accentGreenLight;
        case "industry_news_and_updates":
          return theme.colors.accentGreenLight;
        case "new_features":
        case "product_updates":
          return theme.colors.accentYellowLight;
        case "policy_updates":
        case "account_updates":
          return theme.colors.accentPurpleLight;
        case "promotions_and_special_offers":
          return theme.colors.accentBlue;
        default:
          return theme.colors.accentGreen;
      }
    }
  }};
`;

export const NotificationBody = styled.div`
  margin: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[16]}`};

`;

export const NotificationLink = styled<React.FC<TypographyProps>>(Typography).attrs({
  color: "accentBlue",
})`
  margin-bottom: 0;
`;
