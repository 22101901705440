import { Icon, Typography } from "@hotelengine/atlas-web";
import { NavLink } from "@hotel-engine/lib/react-router-dom";
import { routes, supportCenterRouteSources } from "@hotel-engine/constants";

import * as Styled from "./styles";
import { useFlightModificationsContext } from "../../ModificationsContext";

export interface ICancelledBannerProps {
  isLodging: boolean;
  isRefresh?: boolean;
  isPartiallyCancelled?: boolean;
  /** An optional prop used for non-API flight cancellation requests */
  isPendingCancellation?: boolean;
}

const CancelledBanner = ({
  isLodging,
  isRefresh = false,
  isPartiallyCancelled = false,
  isPendingCancellation = false,
}: ICancelledBannerProps) => {
  const { reservation } = useFlightModificationsContext();

  const getCopy = () => {
    if (isLodging) {
      return isPartiallyCancelled
        ? "Part of your stay has been cancelled."
        : "Your stay has been cancelled.";
    } else {
      return isPendingCancellation
        ? "You've requested to cancel this flight."
        : "This booking has been cancelled.";
    }
  };

  return (
    <Styled.CancelledBannerContainer data-testid="trip-cancelled-banner" $isRefresh={isRefresh}>
      <Typography
        variant="body/sm-strong"
        color={isRefresh ? "foregroundPrimary" : "foregroundDestructive"}
        display="flex"
        alignItems="center"
        gap={8}
      >
        {!!isRefresh && <Icon size="sm" name="hexagon-exclamation" color="foregroundDestructive" />}
        {getCopy()}
      </Typography>
      {!!isPendingCancellation && (
        <Typography
          variant="link/sm"
          as={NavLink}
          to={`${routes.supportCenter}?source=${supportCenterRouteSources.modificationStatuses}&property_query=${reservation?.bookingNumber}`}
          color={isRefresh ? "foregroundPrimary" : "foregroundDestructive"}
          display="flex"
          alignItems="center"
          gap={8}
          style={{ cursor: "pointer " }}
        >
          View in Support Center
        </Typography>
      )}
    </Styled.CancelledBannerContainer>
  );
};

export default CancelledBanner;
