import SummaryOfChargesItem from "../../../shared/SummaryOfChargesItem";
import { Box, Divider, Typography } from "@hotelengine/atlas-web";
import { useCarRefundDetails } from "./useCarRefundDetails";
import { PaddedScrollSectionContainer } from "../../../styles";
import { useCarModificationsContext } from "../../../ModificationsContext";
import { type ICarRefund } from "@hotel-engine/types/itinerary";

export interface IRefundBreakdownsProps {
  refunds: ICarRefund[];
}

const RefundBreakdowns = ({ refunds }: IRefundBreakdownsProps) => {
  const { details } = useCarRefundDetails(refunds);
  const { isPreview } = useCarModificationsContext();

  return (
    <PaddedScrollSectionContainer $isPreview={isPreview}>
      <Typography variant="uppercase/xs-strong" marginBottom={8}>
        Refund details
      </Typography>
      {details.map((group, i) => (
        <Box key={`refund-group-${group.key}`} data-testid={`refund-group-${i}`}>
          {group.items.map((item) => (
            <Box
              key={`refund-${item.label}-${item.value}`}
              data-testid={`refund-${item.label}-${item.value}`}
              display="flex"
              flexDirection="column"
            >
              <SummaryOfChargesItem
                {...item}
                style={{ marginTop: 4, marginBottom: 4 }}
                labelColor="foregroundPrimary"
                valueColor="foregroundPrimary"
              />
            </Box>
          ))}

          {i < details.length - 1 && (
            <Box marginTop={16} marginBottom={16}>
              <Divider variant="dotted" />
            </Box>
          )}
        </Box>
      ))}
    </PaddedScrollSectionContainer>
  );
};

export default RefundBreakdowns;
