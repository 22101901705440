import { Formik } from "formik";

import { InternationalPhoneInput } from "@hotel-engine/app/InternationalPhoneInput";
import { useTwoFactorVerificationCodeAuthenticatedRequest } from "@hotel-engine/react-query/twoFactorAuth/useTwoFactorVerificationCodeRequest";
import { IntlPhoneNumberSchema } from "@hotel-engine/utilities";
import { getPersonalToken } from "@hotel-engine/utilities/auth";
import { ModalNavBar } from "pages/DashboardLegacy/components/PersonalAccountCreationCta/ModalNavBar";
import { useAppSelector } from "store/hooks";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";
import * as Styled from "./styles";

export interface ISignUpWithGoogleStep {
  onSubmit: (phone: string) => void;
  /** Indicates the component is rendering in a modal on the Dashboard on a Business account */
  isInModal?: boolean;
  onClose?: () => void;
}

export interface IPhoneVerification {
  phone: string;
}

const initialValues: IPhoneVerification = {
  phone: "",
};

export const VerifyTwoFactorStep = ({ onSubmit, isInModal, onClose }: ISignUpWithGoogleStep) => {
  const user = useAppSelector((store) => store.Auth.user);

  const isBusinessAccount = user?.accountType === "business";
  const personalToken = getPersonalToken();
  const authToken = isBusinessAccount && personalToken ? { token: personalToken } : undefined;
  const twoFactorAuth = useTwoFactorVerificationCodeAuthenticatedRequest(authToken);
  const { COMPANY_NAME } = useCompanyName();

  const onPhoneNumberSubmit = (values: IPhoneVerification, actions) => {
    // regex - replaces any characters that isn't a digit to ""
    const strippedPhone = values.phone.replace(/[\D]/g, "");

    twoFactorAuth.mutate(
      {
        phone: strippedPhone,
      },
      {
        onSuccess: () => {
          // progress to next step: verifying the verification code

          onSubmit(strippedPhone);
        },
        onError: (error) => {
          return actions.setFieldError(
            "phone",
            error.response?.data.errors[0] || "An error occurred, please try again."
          );
        },
      }
    );
  };

  return (
    <>
      {!!isInModal && !!onClose && <ModalNavBar onClose={onClose} />}
      <Styled.Title $isInModal={!!isInModal}>Set Up Two-Step Verification</Styled.Title>
      <Styled.DescriptionText $isInModal={!!isInModal}>
        Security is critical at {COMPANY_NAME}. To validate your account, we will text you a
        verification code.
      </Styled.DescriptionText>

      <Formik
        initialValues={initialValues}
        onSubmit={onPhoneNumberSubmit}
        validationSchema={() => IntlPhoneNumberSchema()}
      >
        {({ submitForm }) => {
          return (
            <Styled.PhoneForm>
              <div>
                <InternationalPhoneInput label="Phone Number" />

                <Styled.TextRates>Text or data rates may apply.</Styled.TextRates>
              </div>
              <Styled.SubmitButton htmlType="button" type="primary" onClick={submitForm}>
                Send Code
              </Styled.SubmitButton>
            </Styled.PhoneForm>
          );
        }}
      </Formik>
    </>
  );
};
