import { connect } from "react-redux";
import { Link, useLocation } from "@hotel-engine/lib/react-router-dom";

import { Icon } from "@hotel-engine/common/Icon";
import { HELP_CENTER, routes } from "@hotel-engine/constants";
import type { IUser } from "@hotel-engine/types/user";
import { isUserEligibleToViewAndUseRewards } from "@hotel-engine/utilities";
import { ampli } from "ampli";
import config from "config";
import { Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";
import SupportChatLink from "@hotel-engine/app/SupportChatLink";
import { useIsFeatureFlagOn } from "../Experiments";
import { useExperiment } from "@hotel-engine/react-query/amplitude/useExperimentQuery";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

export interface IFooterProps {
  /** the className prop allows this styled component to have their styles be overwritten when reused */
  className?: string;
  /** Current user object */
  user: IUser;
  /** Used to toggle open the customer feedback modal */
  openFeedbackModal: () => void;
}

export const FooterWithRedux = ({ className, user, openFeedbackModal }: IFooterProps) => {
  const { COMPANY_NAME, COMPANY_URL, COMPANY_ABBREVIATION, companySocials } = useCompanyName();
  const MIAWRollout = useExperiment("sf-miaw-rollout", { value: "off" });
  const sfMIAWFeatureFlagEnabled = MIAWRollout.data.value === "on";
  const currencyCode = user.business.currencyCode;
  const { organizationId, supportPhone } = user?.business || {
    disableRewards: false,
    organizationId: null,
    supportPhone: null,
  };
  const location = useLocation();

  const chatLinkReplacementFeatureOn = useIsFeatureFlagOn("chat-link-replacement-members");
  const isDashboardRebrandOn = useIsFeatureFlagOn("rebrand_logo_copy");
  const isNewDashboard = isDashboardRebrandOn && location.pathname === "/";

  const faqURL =
    organizationId === 273
      ? `https://www.${COMPANY_URL}/texas-faqs`
      : `https://www.${COMPANY_URL}/faq`;

  return user ? (
    <Styled.Footer className={className} hideBorder={isNewDashboard}>
      <div className="main-links">
        <div className="link-sections">
          <section>
            <Typography as="h2" variant="body/sm-strong" color="foregroundPrimary">
              Company
            </Typography>
            <ul>
              <li>
                <Typography
                  as="a"
                  color="foregroundPrimary"
                  href={`https://www.${COMPANY_URL}/about-us`}
                  rel="noreferrer"
                  target="_blank"
                  variant="body/sm"
                >
                  About Us
                </Typography>
              </li>
              <li>
                <Typography
                  as="a"
                  color="foregroundPrimary"
                  href={`https://www.${COMPANY_URL}/careers`}
                  rel="noreferrer"
                  target="_blank"
                  variant="body/sm"
                >
                  Careers
                </Typography>
              </li>
              <li>
                <Typography
                  as="a"
                  color="foregroundPrimary"
                  href={`https://www.${COMPANY_URL}/blog/`}
                  rel="noreferrer"
                  target="_blank"
                  variant="body/sm"
                >
                  Blog
                </Typography>
              </li>
            </ul>
          </section>
          <section>
            <Typography
              as="h2"
              variant="body/sm-strong"
              color="foregroundPrimary"
              marginBottom={12}
            >
              Flex
            </Typography>
            <ul>
              <li>
                <Typography
                  as="a"
                  color="foregroundPrimary"
                  href="/flex-overview"
                  target="_blank"
                  variant="body/sm"
                >
                  Flex FAQ
                </Typography>
              </li>
              <li>
                <Typography
                  as="a"
                  color="foregroundPrimary"
                  href="/flexpro-overview"
                  target="_blank"
                  variant="body/sm"
                >
                  FlexPro FAQ
                </Typography>
              </li>
            </ul>
          </section>
          <section>
            <Typography
              as="h2"
              color="foregroundPrimary"
              marginBottom={12}
              variant="body/sm-strong"
            >
              Support
            </Typography>
            <ul>
              <li>
                <Typography
                  as="a"
                  color="foregroundPrimary"
                  href={HELP_CENTER}
                  rel="noreferrer"
                  target="_blank"
                  variant="body/sm"
                >
                  Help Center
                </Typography>
              </li>
              <li>
                <Typography
                  as="a"
                  color="foregroundPrimary"
                  href={faqURL}
                  rel="noreferrer"
                  target="_blank"
                  variant="body/sm"
                >
                  FAQ
                </Typography>
              </li>
              <li>
                <Typography
                  as="a"
                  color="foregroundPrimary"
                  href={`https://www.${COMPANY_URL}/contact-us/`}
                  rel="noreferrer"
                  target="_blank"
                  variant="body/sm"
                >
                  Contact Us
                </Typography>
              </li>
              <li>
                <Link
                  to={routes.groups.base}
                  onClick={() => {
                    ampli.clickStartNewSpecialBookingRequest({
                      source: "footer",
                      userId: user?.id,
                    });
                  }}
                >
                  <Typography variant="body/sm" color="foregroundPrimary">
                    Groups
                  </Typography>
                </Link>
              </li>
              <li>
                <Typography
                  as="a"
                  color="foregroundPrimary"
                  href={`https://www.${COMPANY_URL}/terms-of-service/`}
                  rel="noreferrer"
                  target="_blank"
                  variant="body/sm"
                >
                  Terms & Conditions
                </Typography>
              </li>
            </ul>
          </section>
          {isUserEligibleToViewAndUseRewards(user) && (
            <section>
              <Typography
                as="h2"
                variant="body/sm-strong"
                color="foregroundPrimary"
                marginBottom={12}
              >
                Rewards
              </Typography>
              <ul>
                <li>
                  <Link to={routes.rewardsEnrollment}>
                    <Typography variant="body/sm" color="foregroundPrimary">
                      About Rewards
                    </Typography>
                  </Link>
                </li>
                <li>
                  <Link to={routes.rewardsFaq}>
                    <Typography variant="body/sm" color="foregroundPrimary">
                      Rewards FAQ
                    </Typography>
                  </Link>
                </li>
                <li>
                  <Link to={routes.rewardsToc}>
                    <Typography variant="body/sm" color="foregroundPrimary">
                      Rewards Terms
                    </Typography>
                  </Link>
                </li>
              </ul>
            </section>
          )}
        </div>
        <div className="support-section">
          <section className="support-section">
            <Typography
              as="h2"
              variant="body/sm-strong"
              color="foregroundPrimary"
              marginBottom={12}
            >
              Member Support
            </Typography>
            {!!chatLinkReplacementFeatureOn && !sfMIAWFeatureFlagEnabled ? (
              <Styled.SupportChatLinkContainer aria-label="Get Support">
                <Styled.ButtonTitle>Get Support</Styled.ButtonTitle>
                <Styled.ButtonSubtitle>
                  Reach out to Member Support via {<SupportChatLink text="live chat" />}, available
                  24/7.
                </Styled.ButtonSubtitle>
              </Styled.SupportChatLinkContainer>
            ) : (
              <ul>
                <li>
                  <Typography
                    aria-label="Support phone"
                    as="a"
                    color="foregroundPrimary"
                    href={`tel:${supportPhone || config.supportNumber(currencyCode)}`}
                    title="Support number"
                    variant="body/sm"
                  >
                    {supportPhone || config.supportNumber(currencyCode)}
                  </Typography>
                </li>
                <li>
                  <Typography
                    as="a"
                    color="foregroundPrimary"
                    href={`mailto:support@${COMPANY_URL}`}
                    title="Support email"
                    variant="body/sm"
                  >
                    support@{COMPANY_URL}
                  </Typography>
                </li>
              </ul>
            )}
          </section>
          <section className="review-section">
            <button aria-label="Leave Feedback" onClick={openFeedbackModal}>
              <Typography variant="body/sm-strong" color="foregroundPrimary" marginBottom={8}>
                Leave Feedback
              </Typography>
              <Typography variant="body/sm" color="foregroundPrimary">
                Tell us about your experience!
              </Typography>
            </button>
          </section>
        </div>
      </div>
      <section className="social">
        <ul>
          <li>
            <a
              href={companySocials.facebook}
              target="_blank"
              rel="noopener noreferrer"
              title={`${COMPANY_ABBREVIATION} Facebook`}
            >
              <Icon icon={["fab", "facebook-square"]} />
            </a>
          </li>
          <li>
            <a
              href={companySocials.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              title={`${COMPANY_ABBREVIATION} LinkedIn`}
            >
              <Icon icon={["fab", "linkedin"]} />
            </a>
          </li>
          <li>
            <a
              href={companySocials.twitter}
              target="_blank"
              rel="noopener noreferrer"
              title={`${COMPANY_ABBREVIATION} Twitter`}
            >
              <Icon icon={["fab", "twitter-square"]} />
            </a>
          </li>
        </ul>
        <Typography variant="body/sm" color="foregroundPrimary">
          {COMPANY_NAME}. &copy; {new Date().getFullYear()}. Made in Denver, CO.
        </Typography>
      </section>
    </Styled.Footer>
  ) : null;
};

const mapStateToProps = ({ Auth }) => ({
  user: Auth.user,
});

export default connect(mapStateToProps)(FooterWithRedux);
