import type { FC, MouseEventHandler } from "react";

import { HEIcon } from "@hotel-engine/assets";
import { AutoComplete, AutoCompleteOption } from "@hotel-engine/common/AutoComplete";

import Spin from "@hotel-engine/common/Spin";
import { useGoogleAutoCompleteResults } from "@hotel-engine/hooks/useGoogleAutocompleteResults";
import { sanitizeHtml } from "@hotel-engine/utilities";
import { InputIconContainer } from "../CarsSearchHeader/styles";
import { getIconType } from "./helpers";
import * as Styles from "./styles";
import { Box, Icon, TextInput as Input } from "@hotelengine/atlas-web";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import type { CustomIconName } from "@hotelengine/atlas-core";
import { useIsMobile } from "pages/Cars/hooks/useIsMobile";

export interface ISelectedLocation {
  id: string;
  name: string;
}

export const GoogleAutocompleteInput: FC<{
  id: string;
  onSelect: (location: ISelectedLocation) => void;
  onClear: MouseEventHandler;
  onBlur?: (value) => void;
  value: string;
  onUpdateValue: (val: string) => void;
  iconName?: string;
  isIconSolid?: boolean;
  placeholder?: string;
  isInvalid?: boolean;
  isRebrand?: boolean;
}> = ({
  id,
  onSelect,
  onClear,
  onBlur,
  value,
  onUpdateValue,
  iconName = "car",
  isIconSolid = true,
  placeholder = "Where to?",
  isInvalid = false,
  isRebrand = false,
}) => {
  const { isAutocompleteLoading, predictions } = useGoogleAutoCompleteResults(value);
  const isMobile = useIsMobile();

  const handleSelect = (selectedValueId) => {
    const result = predictions.find((place) => place.place_id === selectedValueId);

    if (!result) return;

    onSelect({
      id: result.place_id,
      name: result.description,
    });
    onUpdateValue(result.description);
  };

  //  helpers
  const getPopupContainer = () => {
    const mainContainer = document.getElementById("location-input-container");
    return mainContainer || document.body;
  };

  // Antd prop components
  const InputSuffix = () => (
    <>
      {!!isInvalid && (
        <Box>
          <Icon size="md" name="hexagon-exclamation--solid" color="sentimentCriticalStrong" />
        </Box>
      )}
      {!!isAutocompleteLoading && <Spin size="small" />}
      {!isAutocompleteLoading && Boolean(value) && (
        <Styles.RightIcon role="button" onClick={onClear}>
          <Icon name="xmark" />
        </Styles.RightIcon>
      )}
    </>
  );

  const renderAutoCompleteOptions = () => {
    const boldQuery = (description: string) => {
      const regex = new RegExp(value, "ig");
      return description?.replace(regex, (match) => `<strong>${match}</strong>`);
    };

    return [
      ...predictions.map((location) => (
        <AutoCompleteOption
          key={`searchResults-${location.place_id}-${id}`}
          data-testid="dropdown-option"
          value={location.place_id}
          style={{ padding: 0 }}
        >
          <Styles.OptionContainer $rebrand={isRebrand}>
            <Styles.OptionIcon $rebrand={isRebrand}>
              <HEIcon type={getIconType(location.types)} />
            </Styles.OptionIcon>
            <Styles.OptionDescription>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(boldQuery(location.description || "")),
                }}
              />
            </Styles.OptionDescription>
          </Styles.OptionContainer>
        </AutoCompleteOption>
      )),
    ];
  };

  return (
    <AutoComplete
      className={`location-input-autocomplete-${id}`}
      dataSource={renderAutoCompleteOptions()}
      dropdownClassName={`location-input-dropdown-${id}`}
      dropdownStyle={{
        padding: 0,
        borderRadius: 24,
        marginTop: 12,
        transform: "translateY(18px)",
      }}
      dropdownMenuStyle={{
        padding: 12,
        gap: 9,
        display: "flex",
        flexDirection: "column",
        maxHeight: "unset",
      }}
      dropdownMatchSelectWidth={isMobile}
      getPopupContainer={getPopupContainer}
      onBlur={onBlur}
      onChange={(newValue) => onUpdateValue(newValue.toString())}
      onSelect={handleSelect}
      size="large"
      value={value}
      data-testid="location-autocomplete"
    >
      <Input
        placeholder={placeholder}
        leadingAdornment={
          <InputIconContainer>
            <Icon
              name={`${iconName}${isIconSolid ? "--solid" : ""}` as IconName & CustomIconName}
              color="inputForegroundHelper"
            />
          </InputIconContainer>
        }
        trailingAdornment={<InputSuffix />}
        autoComplete="nope"
        data-testid={`location-input-${id}`}
      />
    </AutoComplete>
  );
};
