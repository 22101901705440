import { noop } from "lodash";
import { ampli } from "ampli";

import {
  BILLING,
  COMPANY_SETTINGS,
  DASHBOARD,
  GROUPS,
  MEMBERS,
  TRENDS,
  TRIPS,
  SUPPORT_CENTER,
  routes,
  supportCenterRouteSources,
  ORDERS,
  PREFERENCES,
  TRAVEL_POLICIES,
} from "@hotel-engine/constants";
import { isUserEnrolledInRewards } from "@hotel-engine/utilities";

import type { IRole } from "@hotel-engine/types/role";
import type { IUser } from "@hotel-engine/types/user";
import type { NavigateFunction } from "@hotel-engine/lib/react-router-dom";
import type { ITokenMode } from "@hotel-engine/react-query/users/useAccountMode";
import type { SubMenuCards } from "@hotel-engine/app/Header/components/redesignComponents/TopNavMenu";

export const hePerkBusinessId = 260129;

export const userRoleMap: Record<IRole, string> = {
  admin: "Administrator",
  coordinator: "Coordinator",
  user: "Traveler",
  view_only_traveler: "View Only Traveler",
};

export const roleLinks: Record<IRole, ILinks["id"][]> = {
  admin: [DASHBOARD, TRIPS, MEMBERS, TRENDS, GROUPS, COMPANY_SETTINGS, BILLING, SUPPORT_CENTER],
  coordinator: [DASHBOARD, TRIPS, TRENDS, GROUPS, SUPPORT_CENTER],
  user: [DASHBOARD, TRIPS, GROUPS, SUPPORT_CENTER],
  view_only_traveler: [TRIPS, SUPPORT_CENTER],
};

type LinksValueId =
  | typeof DASHBOARD
  | typeof TRIPS
  | typeof MEMBERS
  | typeof BILLING
  | typeof COMPANY_SETTINGS
  | typeof TRENDS
  | typeof GROUPS
  | typeof SUPPORT_CENTER
  | typeof TRAVEL_POLICIES
  | typeof PREFERENCES;

type LinkValueLinkType = {
  id: LinksValueId;
  name: string;
  route: string;
  type: "link";
  redesignName?: string;
  subMenuItems?: SubMenuCards;
};

type LinkValueMenuTriggerType = Omit<LinkValueLinkType, "type"> & {
  type: "menu-trigger";
  subMenuItems: SubMenuCards;
};

type LinkValueDynamicType = Omit<LinkValueLinkType, "type"> & {
  type: "dynamic";
  subMenuItems?: SubMenuCards;
};

export type ILinks = LinkValueLinkType | LinkValueMenuTriggerType | LinkValueDynamicType;

export const LinkValues: ILinks[] = [
  {
    id: DASHBOARD,
    name: "Book",
    route: "/",
    type: "link",
  },
  {
    id: TRIPS,
    name: "Trips",
    route: routes.trips.upcoming,
    type: "link",
  },
  // this link will go away once old dropdown is sunsetted
  {
    id: MEMBERS,
    name: "Members",
    route: routes.members.all,
    type: "link",
  },
  // this link will go away once old dropdown is sunsetted
  {
    id: TRENDS,
    name: "Trends",
    route: routes.trends.all,
    type: "link",
  },
  {
    // this can be a link or menu trigger depending on user role
    id: GROUPS,
    name: "Groups",
    route: `${routes.groups.dashboard}/open`,
    type: "dynamic",
    subMenuItems: [
      {
        id: GROUPS,
        title: "Groups",
        icon: "users-rectangle",
        href: `${routes.groups.dashboard}/open`,
        onClick: () => {
          ampli.clickGroupsSubNavSelection({ selectedPage: "groups" });
        },
      },
      {
        id: ORDERS,
        title: "Orders",
        icon: "truck-pickup",
        href: routes.orders,
        onClick: () => {
          ampli.clickGroupsSubNavSelection({ selectedPage: "orders" });
        },
      },
    ],
  },
  {
    id: COMPANY_SETTINGS,
    name: "Company Settings",
    route: routes.companySettings.userPermissions,
    type: "menu-trigger",
    // TODO: need paths, ampli events for these links
    subMenuItems: [
      {
        id: TRENDS,
        title: "Trends",
        icon: "wave-pulse",
        content: "Track spending and savings",
        href: routes.trends.all,
      },
      {
        id: TRAVEL_POLICIES,
        title: "Policies",
        icon: "shield-keyhole",
        content: "Customize traveler tiers",
        href: routes.companySettings.travelPolicies,
        showOnMobile: true,
      },
      {
        id: PREFERENCES,
        title: "Settings",
        icon: "list-check",
        content: "Dial in company preferences",
        href: routes.companySettings.userPermissions,
        showOnMobile: true,
      },
      {
        id: MEMBERS,
        title: "Members",
        icon: "users-rectangle",
        content: "Manage team members",
        href: routes.members.all,
      },
    ],
    redesignName: "Company",
  },
  {
    id: BILLING,
    name: "Billing",
    route: routes.billing,
    type: "link",
  },
  {
    id: SUPPORT_CENTER,
    name: "Support Center",
    route: `${routes.supportCenter}?source=${supportCenterRouteSources.menuBar}`,
    type: "link",
    redesignName: "Support",
  },
];

interface IGetUserNavLinks {
  shouldShowReferAFriend: boolean;
  shouldShowTravelCredits: boolean;
  shouldShowRewards: boolean;
  shouldShowMyProperties: boolean;
  isInviteTravelerEnabled: boolean;
  supportCenterFeatureOn: boolean;
  canCreatePersonalAccount: boolean;
  showSwitch: boolean;
  userTokenMode: ITokenMode;
  navigate: NavigateFunction;
  onInviteTraveler: () => void;
  handleSupportCaseClick: () => () => void;
  onPersonalAccountCreation?: () => void;
  onToggleBizPersonalAccount: () => void;
  isRedesign?: boolean;
}

export const getUserNavLinks = ({
  navigate,
  shouldShowReferAFriend,
  shouldShowRewards,
  shouldShowTravelCredits,
  shouldShowMyProperties,
  onInviteTraveler,
  isInviteTravelerEnabled,
  supportCenterFeatureOn,
  handleSupportCaseClick,
  canCreatePersonalAccount,
  onPersonalAccountCreation = noop,
  userTokenMode,
  showSwitch,
  onToggleBizPersonalAccount,
  isRedesign,
}: IGetUserNavLinks) => {
  // TODO: remove this branching logic once old header sunsetted
  return isRedesign
    ? [
        {
          icon: "he-rewards--solid",
          label: "Rewards",
          shouldShow: shouldShowRewards,
          onClick: (user: IUser) =>
            navigate(isUserEnrolledInRewards(user) ? routes.rewards : routes.rewardsEnrollment),
        },
        {
          icon: "money-bill-simple-wave--solid",
          label: "Travel credit",
          shouldShow: shouldShowTravelCredits,
          onClick: () => navigate(routes.travelCredits),
        },
        {
          icon: "heart--solid",
          label: "Favorites",
          shouldShow: shouldShowMyProperties,
          onClick: () => navigate(routes.myPropertiesFavorites),
        },
        {
          icon: "user-plus",
          label: "Invite traveler",
          shouldShow: isInviteTravelerEnabled,
          onClick: onInviteTraveler,
        },
        {
          icon: "gear--solid",
          label: "Settings",
          shouldShow: true,
          onClick: () => navigate(routes.settings.profile),
        },
        {
          icon: "gift",
          label: "Refer a Friend",
          redesignLabel: "Refer a friend",
          shouldShow: shouldShowReferAFriend,
          onClick: () => navigate(routes.referFriends.base),
        },
        {
          icon: "message-lines",
          label: "Support Center",
          redesignLabel: "Support center",
          shouldShow: supportCenterFeatureOn,
          onClick: handleSupportCaseClick,
        },
        {
          icon: "plus",
          label: "Add Personal Account",
          redesignLabel: "Add personal account",
          shouldShow: canCreatePersonalAccount,
          onClick: onPersonalAccountCreation,
        },
      ]
    : ([
        {
          icon: "gift",
          label: "Refer a Friend",
          redesignLabel: "Refer a friend",
          shouldShow: shouldShowReferAFriend,
          onClick: () => navigate(routes.referFriends.base),
        },
        {
          icon: "money-bill-simple-wave--solid",
          label: "Travel Credit",
          shouldShow: shouldShowTravelCredits,
          onClick: () => navigate(routes.travelCredits),
        },
        {
          icon: "he-rewards--solid",
          label: "My Rewards",
          shouldShow: shouldShowRewards,
          onClick: (user: IUser) =>
            navigate(isUserEnrolledInRewards(user) ? routes.rewards : routes.rewardsEnrollment),
        },
        {
          icon: "heart--solid",
          label: "My Properties",
          shouldShow: shouldShowMyProperties,
          onClick: () => navigate(routes.myPropertiesPreferred),
        },
        {
          icon: "user-plus",
          label: "Invite Traveler",
          shouldShow: isInviteTravelerEnabled,
          onClick: onInviteTraveler,
        },
        {
          icon: "gear--solid",
          label: "My Settings",
          shouldShow: true,
          onClick: () => navigate(routes.settings.profile),
        },
        {
          icon: "message-lines",
          label: "Support Center",
          shouldShow: supportCenterFeatureOn,
          onClick: handleSupportCaseClick,
        },
        {
          icon: "plus",
          label: "Add Personal Account",
          shouldShow: canCreatePersonalAccount,
          onClick: onPersonalAccountCreation,
        },
        // this link will go away once old dropdown is sunsetted
        {
          icon: "arrows-rotate",
          label: `Switch to ${userTokenMode === "personal" ? "Business" : "Personal"} Account`,
          shouldShow: showSwitch,
          onClick: onToggleBizPersonalAccount,
        },
      ] as const);
};

interface IGetInternalBusinessNavLinks {
  showEngines: boolean;
  showRatelessProperties: boolean;
  showSearchRadiusCircle: boolean;
  toggleEnginePreferences: (toggle: boolean) => void;
  toggleRatelessProperties: (toggle: boolean) => void;
  toggleSearchRadiusCircle: (toggle: boolean) => void;
}

export const getInternalBusinessNavLinks = ({
  showEngines,
  showRatelessProperties,
  showSearchRadiusCircle,
  toggleEnginePreferences,
  toggleRatelessProperties,
  toggleSearchRadiusCircle,
}: IGetInternalBusinessNavLinks) => {
  return [
    {
      label: "Show Engines",
      isChecked: showEngines,
      onChange: () => toggleEnginePreferences(!showEngines),
    },
    {
      label: "Show rateless properties",
      isChecked: showRatelessProperties,
      onChange: () => toggleRatelessProperties(!showRatelessProperties),
    },
    {
      label: "Show search radius circle",
      isChecked: showSearchRadiusCircle,
      onChange: () => toggleSearchRadiusCircle(!showSearchRadiusCircle),
    },
  ] as const;
};

export const headerBackgroundColorOverrides = {
  [routes.flexPassFAQ]: "linear-gradient(90deg, rgba(239, 251, 242), 47%, rgba(125, 219, 137) 50%)",
  [routes.rewards]: "brandSecondary",
} as const;
