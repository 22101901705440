import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import { useAirportInfo } from "pages/Flights/hooks/useAirportInfo";
import { useCityInfo } from "pages/Flights/hooks/useCityInfo";
import * as Styled from "./styles";

const SlicesHeader = () => {
  const { isPreview, typographyVariants } = useTypographyScaling();
  const { reservation } = useFlightModificationsContext();
  const { getIataCityCodeByIataAirportCode } = useAirportInfo();
  const { getCityNameByIataCityCode } = useCityInfo();

  if (!reservation) return null;

  // This will provide fallback logic until the flightType key is added to the response
  const isOneWay = reservation.flightTravelType === "one_way" || reservation.slices.length === 1;
  const isMultiCity = reservation.flightTravelType === "multi_city";
  // If the flight is oneWay or roundTrip we only need to look at the first slice to get the cities we need
  const slicesToUse = isMultiCity ? reservation.slices : reservation.slices.slice(0, 1);

  const citiesBySlice = slicesToUse.map((slice) => {
    const originCityCode = getIataCityCodeByIataAirportCode(slice.segments[0].origin.airportCode);
    const originCity = getCityNameByIataCityCode(originCityCode);
    const destinationCityCode = getIataCityCodeByIataAirportCode(
      slice.segments[slice.segments.length - 1].destination.airportCode
    );
    const destinationCity = getCityNameByIataCityCode(destinationCityCode);

    return { originCity, destinationCity };
  });

  return (
    <Styled.Container>
      {citiesBySlice.map((cities) => (
        <Box display="flex" flexDirection="row" key={cities.originCity} gap={4}>
          <Styled.DepartureContainer>
            <Typography
              variant={typographyVariants.largeTitle}
              color="foregroundPrimary"
              data-testid={`origin-city-${cities.originCity}`}
              style={{ textAlign: "start" }}
            >
              {cities.originCity}
            </Typography>
          </Styled.DepartureContainer>
          <Styled.ArrowContainer>
            <Icon
              size={isPreview ? "sm" : "md"}
              name={isOneWay || isMultiCity ? "arrow-right" : "arrow-right-arrow-left"}
              color="foregroundPrimary"
            />
          </Styled.ArrowContainer>
          <Styled.ArrivalContainer>
            <Typography
              variant={typographyVariants.largeTitle}
              color="foregroundPrimary"
              data-testid={`destination-city-${cities.destinationCity}`}
              style={{ textAlign: "end" }}
            >
              {cities.destinationCity}
            </Typography>
          </Styled.ArrivalContainer>
        </Box>
      ))}
    </Styled.Container>
  );
};

export default SlicesHeader;
