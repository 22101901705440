import type { IFlightItinerary } from "@hotel-engine/types/itinerary";

import FlightMarkers from "../Markers/FlightMarkers";
import useGoogleFlightLocations from "./useGoogleFlightLocations";
import { SPECIFIC_PROPERTY_ZOOM } from "../../constants";
import * as Styled from "./styles";
import GoogleMap from "../..";

interface IFlightMapProps {
  itinerary: IFlightItinerary;
  isMobile?: boolean;
}

const GoogleFlightMap = ({ itinerary, isMobile }: IFlightMapProps) => {
  const sliceLocations = itinerary.slices.map((slice) => {
    return {
      originCode: slice.segments[0].origin.airportCode,
      destinationCode: slice.segments[slice.segments.length - 1].destination.airportCode,
    };
  });

  const { locations, isError, isLoading } = useGoogleFlightLocations(
    sliceLocations,
    itinerary.flightTravelType,
    isMobile || false
  );

  if (!locations?.[0]?.origin || !locations?.[0]?.destination) {
    return null;
  }

  const Container = ({ children }) => {
    if (isMobile) {
      return <Styled.MobileWrapper>{children}</Styled.MobileWrapper>;
    } else {
      return children;
    }
  };

  return (
    <Container>
      {!!isLoading && !isError ? (
        <Styled.Spinner size="large" />
      ) : (
        <GoogleMap
          dataTestId="itinerary-google-map"
          mapLocation="itinerary"
          mapOptions={{
            center: { lat: locations[0].origin?.lat || 0, lng: locations[0].origin?.lng || 0 },
            zoom: SPECIFIC_PROPERTY_ZOOM,
            // This allows us to zoom out enough to show any full flight path across the world
            minZoom: 2,
          }}
          controlOptions={{
            showExpand: !isMobile,
            showZoom: !isMobile,
            showType: !isMobile,
          }}
        >
          {(map) => <FlightMarkers map={map} locations={locations} isMobile={isMobile} />}
        </GoogleMap>
      )}
    </Container>
  );
};

export default GoogleFlightMap;
