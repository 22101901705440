import type { ReactElement } from "react";
import { Link } from "@hotel-engine/lib/react-router-dom";

import {
  TopNavMenuTrigger,
  TopNavMenuContent,
  TopNavCardList,
  TopNavCard,
} from "@hotelengine/atlas-web";
import type { IconName } from "@hotelengine/atlas-core";
import type { ILinks } from "@hotel-engine/app/Header/constants";

import { StyledTopNavMenu } from "./styles";

export type SubMenuCards = {
  id: string;
  title: string;
  icon: IconName;
  href?: string;
  onClick?: () => void;
  content?: string | ReactElement;
  showOnMobile?: boolean;
}[];

interface ITopNavMenu {
  triggerLabel: string;
  cards: SubMenuCards;
  activePathId: ILinks["id"];
  isMobile: boolean;
}

export const TopNavMenu = ({ triggerLabel, cards, activePathId, isMobile }: ITopNavMenu) => {
  return (
    <StyledTopNavMenu>
      <TopNavMenuTrigger as={Link} label={triggerLabel} to="#" />
      <TopNavMenuContent>
        <TopNavCardList>
          {cards
            .filter((card) => !isMobile || (isMobile && card.showOnMobile))
            .map(({ title, icon, href, content, id }) => (
              <TopNavCard
                isActive={activePathId === id}
                as={Link}
                to={href ?? "#"}
                key={title}
                title={title}
                icon={icon}
              >
                {content}
              </TopNavCard>
            ))}
        </TopNavCardList>
      </TopNavMenuContent>
    </StyledTopNavMenu>
  );
};
