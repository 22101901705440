import { type UseMutationOptions, useMutation } from "react-query";

import { useApi } from "@hotel-engine/react-query/useApi";
import { endpoints } from "@hotel-engine/react-query/constants";
import type { IErrorResponse } from "../../types/errors";

export interface ICancelParams {
  id: number;
}

export interface ICancelData {
  bookingId: string;
  bookingType: string;
  isApiCancellable: boolean;
  refundDetails: {
    fees?: {
      currencyCode: string;
      value: string;
    }[];
    credits?: {
      amount: string;
      type: string;
    }[];
    total: {
      currencyCode: string;
      value: string;
    };
  } | null;
}

export interface ICancelCalcBody {
  bookingNumber: string;
  bookingType: "flight" | "lodging" | "car";
}

export const usePreviewCancellationQuery = (
  params: ICancelParams,
  options?: UseMutationOptions<ICancelData, IErrorResponse, ICancelCalcBody>
) => {
  const post = useApi("post");

  return useMutation(
    [endpoints.trips, params],
    (body) => post<ICancelData>(`${endpoints.trips}/${params.id}/cancellation`, body),
    options
  );
};
