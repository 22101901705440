import { useEffect } from "react";
import { useInbox } from "@trycourier/react-inbox";
import { ampli } from "ampli";

import type { IUser } from "@hotel-engine/types/user";

interface IUseNotificationsTray {
  isRedesign24?: boolean;
  accountType: IUser["accountType"];
  setShowUnreadNotifications: (setUnread: boolean) => void;
}

export const useNotificationsTray = ({
  setShowUnreadNotifications,
  isRedesign24,
  accountType,
}: IUseNotificationsTray) => {
  const { getUnreadMessageCount, unreadMessageCount, ...rest } = useInbox();
  const hasUnreadMessages = unreadMessageCount > 0;
  let badgeText: string | undefined;
  if (isRedesign24) {
    badgeText = unreadMessageCount < 9 ? String(unreadMessageCount) : "9+";
  }
  useEffect(() => {
    getUnreadMessageCount();
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);
  useEffect(() => {
    setShowUnreadNotifications(hasUnreadMessages);
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMessageCount, accountType]);
  const trackNotificationsOpen = (visible: boolean) => {
    if (visible) {
      ampli.viewNotifications();
    }
  };
  return { badgeText, trackNotificationsOpen, unreadMessageCount, rest };
};
