import { Avatar, Box, Button, Icon, Typography } from "@hotelengine/atlas-web";
import * as Styled from "../../styles";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import type { BookingDetailsType } from "@hotel-engine/types/supportCase";
import { routes } from "@hotel-engine/constants";
import { useIsFeatureFlagOn } from "../../../Experiments";
import { useAirlineInfo } from "pages/Flights/hooks/useAirlineInfo";

export interface IBookingDetails {
  booking: BookingDetailsType;
  closeDrawer?: () => void;
}

const BookingDetails = ({ booking, closeDrawer }: IBookingDetails) => {
  const { data: bookingData, isFlightBooking, isCarBooking } = booking;

  const isFlightsOn = useIsFeatureFlagOn("flights-postbooking");
  const navigate = useNavigate();
  const { getAirlineNameFromIataAirlineCode } = useAirlineInfo();

  if (isFlightBooking) {
    const itineraryNumber = bookingData?.bookingNumber;
    const airlineConfirmationNumber = bookingData?.firstSegment?.pnrCode;
    const flightNumber = bookingData?.firstSegment?.flightNumber;

    const airlineName = bookingData?.firstSegment?.airlineCode
      ? getAirlineNameFromIataAirlineCode(bookingData.firstSegment.airlineCode)
      : null;

    const flightDetail = `${airlineName} ${flightNumber}`;
    const bookedBy = bookingData?.bookerFullName;

    return (
      <>
        <Styled.Row>
          <Typography variant="heading/sm">Booking Details</Typography>
        </Styled.Row>

        {!!itineraryNumber && (
          <Styled.Row>
            <Typography variant="body/md">Itinerary Number:</Typography>
            <Typography variant="body/md">{itineraryNumber}</Typography>
          </Styled.Row>
        )}

        {!!airlineConfirmationNumber && (
          <Styled.Row>
            <Typography variant="body/md">Airline Confirmation:</Typography>
            <Typography variant="body/md">{airlineConfirmationNumber}</Typography>
          </Styled.Row>
        )}

        {!!flightDetail && (
          <Styled.Row>
            <Typography variant="body/md">Flight:</Typography>
            <Typography variant="body/md">{flightDetail}</Typography>
          </Styled.Row>
        )}

        {!!bookedBy && (
          <Styled.Row>
            <Typography variant="body/md">Booked By:</Typography>
            <Typography variant="body/md">{bookedBy}</Typography>
          </Styled.Row>
        )}

        {!!isFlightsOn && (
          <Button
            onClick={() => {
              closeDrawer && closeDrawer();
              navigate({
                pathname: routes.itinerary,
                search: `?contract_number=${bookingData.tripId}&type=flight`,
              });
            }}
            hasHorizontalBleed
            variant="plain"
            data-testid="support-case-drawer-view-flight-itinerary"
          >
            <Typography variant="link/sm" color="foregroundSecondary">
              View trip itinerary
            </Typography>
          </Button>
        )}
      </>
    );
  }

  if (isCarBooking) {
    const bookingNumber = bookingData?.bookingNumber;
    const vendorName = bookingData?.vendorName;
    const bookerFullName = bookingData?.bookerFullName;
    const pickupAddress = bookingData?.vendorPickupAddress;

    return (
      <Styled.DetailsWrapper>
        <Styled.Row>
          <Typography variant="heading/sm">Booking Details</Typography>
        </Styled.Row>

        {!!bookingNumber && (
          <Styled.Row>
            <Typography variant="body/sm-light" color="foregroundSecondary">
              Booking number:
            </Typography>
            <Box display="flex" alignItems="center" gap={4}>
              <Icon name="car-side--solid" size="md" />
              <Typography variant="body/sm" color="foregroundPrimary">
                {bookingNumber}
              </Typography>
            </Box>
          </Styled.Row>
        )}

        {!!vendorName && (
          <Styled.Row>
            <Typography variant="body/sm-light" color="foregroundSecondary">
              Vendor name:
            </Typography>
            <Typography variant="body/sm" color="foregroundPrimary">
              {vendorName}
            </Typography>
          </Styled.Row>
        )}

        {!!pickupAddress && (
          <Styled.Row>
            <Typography variant="body/sm-light" color="foregroundSecondary">
              Pick-up address:
            </Typography>
            <Box display="flex" alignItems="center" gap={4}>
              <Icon name="location-dot" size="md" />
              <Typography variant="body/sm" color="foregroundPrimary">
                {pickupAddress}
              </Typography>
            </Box>
          </Styled.Row>
        )}

        {!!bookerFullName && (
          <Styled.Row>
            <Typography variant="body/sm-light" color="foregroundSecondary">
              Booker full name:
            </Typography>
            <Box display="flex" alignItems="center" gap={4}>
              <Avatar size={24} name={bookerFullName} />
              <Typography variant="body/sm" color="foregroundPrimary">
                {bookerFullName}
              </Typography>
            </Box>
          </Styled.Row>
        )}
      </Styled.DetailsWrapper>
    );
  }

  const contractNumber =
    bookingData?.groupContractNumber ?? bookingData?.rooms?.[0]?.contractNumber;

  const propertyName = bookingData?.propertyName;

  const propertyLocation = bookingData?.propertyLocation;

  const bookedBy = bookingData?.bookedBy;

  return (
    <Styled.DetailsWrapper>
      <Styled.Row>
        <Typography variant="heading/sm">Booking Details</Typography>
        <Button
          onClick={() => {
            navigate({
              pathname: routes.itinerary,
              search: `?contract_number=${contractNumber}`,
            });
          }}
          hasHorizontalBleed
          variant="plain"
          data-testid="support-case-drawer-view-itinerary"
        >
          <Typography variant="link/sm" color="foregroundSecondary">
            View trip itinerary
          </Typography>
        </Button>
      </Styled.Row>

      {!!contractNumber && (
        <Styled.Row>
          <Typography variant="body/sm-light" color="foregroundSecondary">
            Booking #
          </Typography>
          <Box display="flex" alignItems="center">
            <Icon name="hotel" size="md" />
            <Typography variant="body/sm" color="foregroundPrimary" marginLeft={4}>
              {contractNumber}
            </Typography>
          </Box>
        </Styled.Row>
      )}

      {!!propertyName && (
        <Styled.Row>
          <Typography variant="body/sm-light" color="foregroundSecondary">
            Property
          </Typography>
          <Typography variant="body/sm" color="foregroundPrimary">
            {propertyName}
          </Typography>
        </Styled.Row>
      )}

      {!!propertyLocation && (
        <Styled.Row>
          <Typography variant="body/sm-light" color="foregroundSecondary">
            Location
          </Typography>
          <Box display="flex" alignItems="center">
            <Icon name="location-dot" size="md" />
            <Typography variant="body/sm" color="foregroundPrimary" marginLeft={4}>
              {propertyLocation}
            </Typography>
          </Box>
        </Styled.Row>
      )}

      {!!bookedBy && (
        <Styled.Row>
          <Typography variant="body/sm-light" color="foregroundSecondary">
            Primary traveler
          </Typography>
          <Typography variant="body/sm" color="foregroundPrimary">
            <Avatar size={24} name={bookedBy} /> {bookedBy}
          </Typography>
        </Styled.Row>
      )}
    </Styled.DetailsWrapper>
  );
};

export default BookingDetails;
