import { Box, Button, Icon, TextInput } from "@hotelengine/atlas-web";
import { useFormikContext } from "formik";
import type { Moment } from "moment";
import { defaultPickupDropoffDateTimes } from "pages/Cars/Search/helpers";
import type { ISearchModel } from "pages/Cars/Search/types";
import { useEffect, useState } from "react";
import { START_DATE, END_DATE } from "react-dates/constants";
import { ValidationError } from "../../../ValidationError";
import type { InputFocused } from "../LocationModal";
import { LocationModal } from "../LocationModal";
import { MobileDatesSelector } from "../MobileDatesSelector";
import { LocationInput, LocationInputsWrapper, MobileFormWrapper } from "../styles";
import { InputWrapper } from "../../styles";

type FocusedInput = END_DATE | START_DATE | null;

export type TFormattedGoogleSuggestion = google.maps.places.AutocompletePrediction & {
  icon: "location-dot--solid" | "plane--solid";
};

interface ISearchFormProps {
  onClose?: () => void;
}

export const SearchForm = ({ onClose }: ISearchFormProps) => {
  const { values, errors, setFieldValue, validateForm, submitForm } =
    useFormikContext<ISearchModel>();
  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null);
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [locationFocusedInput, setLocationFocusedInput] = useState<InputFocused>();
  const [sameLocation, setSameLocation] = useState<boolean>(
    values.pickupLocationId === values.dropoffLocationId
  );

  const calendarChangeHandler = (startDate: Moment | null, endDate: Moment | null) => {
    if (startDate === endDate && !!startDate && !!endDate) {
      return setFocusedInput("startDate");
    }

    setFieldValue("pickupDate", startDate);
    if (startDate !== null && endDate !== null && startDate > endDate) {
      setFieldValue("dropoffDate", startDate.clone().add(1, "days"));
      setFocusedInput(END_DATE);
    } else {
      setFieldValue("dropoffDate", endDate || startDate?.clone().add(1, "days"));
      setFocusedInput(!endDate ? END_DATE : START_DATE);
    }
  };

  useEffect(() => {
    if (!values.pickupDate && !values.dropoffDate) {
      const defaultDateTimes = defaultPickupDropoffDateTimes();

      setFieldValue("pickupDate", defaultDateTimes.pickupDate);
      setFieldValue("pickupTime", defaultDateTimes.pickupTime);
      setFieldValue("dropoffDate", defaultDateTimes.dropoffDate);
      setFieldValue("dropoffTime", defaultDateTimes.dropoffTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LocationModal
        showModal={showLocationModal}
        onClose={() => {
          setShowLocationModal(false);
          setSameLocation(values.pickupLocationId === values.dropoffLocationId);
        }}
        focusedInput={locationFocusedInput}
        setInputFocused={setLocationFocusedInput}
        sameLocation={sameLocation}
        setSameLocation={setSameLocation}
      />

      <MobileFormWrapper>
        <Box>
          <LocationInputsWrapper>
            <LocationInput
              $isSameLocation={false}
              isInvalid={!!errors.pickupLocationName}
              value={values.pickupLocationName ?? ""}
              placeholder="Pick-up location"
              onClick={() => {
                setLocationFocusedInput("pickup");
                setShowLocationModal(true);
              }}
              leadingAdornment={
                <Icon name="car-circle-arrow-up--solid" color="inputForegroundHelper" />
              }
            />
            <LocationInput
              $isSameLocation={false}
              isInvalid={!errors.pickupLocationName && !!errors.dropoffLocationName}
              value={values.dropoffLocationName ?? ""}
              placeholder="Drop-off location"
              onClick={() => {
                setLocationFocusedInput("dropoff");
                setSameLocation(false);
                setShowLocationModal(true);
              }}
              leadingAdornment={
                <Icon name="car-circle-arrow-down--solid" color="inputForegroundHelper" />
              }
            />
          </LocationInputsWrapper>
          <ValidationError
            isMobileScreen
            error={errors.pickupLocationName || errors.dropoffLocationName}
          />
        </Box>
        <Box>
          <MobileDatesSelector
            isInvalid={
              !!(errors.pickupDate || errors.pickupTime || errors.dropoffDate || errors.dropoffTime)
            }
            focusedInput={focusedInput}
            setFocusedInput={(focus) => {
              setFocusedInput(focus || "startDate");
            }}
            calendarChangeHandler={calendarChangeHandler}
          />
          <ValidationError
            isMobileScreen
            error={
              errors.pickupDate || errors.pickupTime || errors.dropoffDate || errors.dropoffTime
            }
          />
        </Box>
        <InputWrapper className="age">
          <TextInput
            isInvalid={!!errors.age}
            prefix="Age"
            name="age"
            placeholder="25+"
            type="number"
            value={values.age}
            size="xl"
            max={110}
            onChange={(e) => setFieldValue("age", e.target.value)}
            leadingAdornment={<Icon name="id-card" color="inputForegroundHelper" />}
          />
          <ValidationError isMobileScreen error={errors.age} />
        </InputWrapper>
        <Button
          type="submit"
          color="primary"
          leadingIcon="magnifying-glass"
          size="xl"
          onClick={async () => {
            const syncErrors = await validateForm(values);
            if (Object.keys(syncErrors).length === 0) {
              await submitForm();
              onClose?.();
            }
          }}
        >
          Search cars
        </Button>
      </MobileFormWrapper>
    </>
  );
};
