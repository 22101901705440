import type { Moment } from "moment/moment";
import moment from "moment/moment";
import type { ICarLocation, ICarsSearchParams, IGeoRadius } from "./types";

export function generateSearchParams(
  pickup: ICarLocation,
  dropoff: ICarLocation,
  age: number | undefined,
  isPickupAirport: boolean,
  isDropoffAirport: boolean
): ICarsSearchParams {
  const pickupGeoRadius = (pickup.value as IGeoRadius).geoRadius;
  const dropoffGeoRadius = (dropoff.value as IGeoRadius).geoRadius;
  const limiPickupRadius = isPickupAirport && pickupGeoRadius && !pickupGeoRadius.radius;
  const limitDropoffRadius = isDropoffAirport && dropoffGeoRadius && !dropoffGeoRadius.radius;
  const pickupLocation = pickup.value;
  const dropoffLocation = dropoff.value;
  if (limiPickupRadius) {
    (pickupLocation as IGeoRadius).geoRadius.radius = {
      unit: "mi",
      value: 5,
    };
  }
  if (limitDropoffRadius) {
    (dropoffLocation as IGeoRadius).geoRadius.radius = {
      unit: "mi",
      value: 5,
    };
  }

  return {
    pickupTime: pickup.time,
    dropoffTime: dropoff.time,
    pickupLocation,
    dropoffLocation,
    driversAge: age ?? 25,
  };
}

export const parseFormattedDateTime = (date: string, time: string) => {
  return moment.utc(`${date}T${time}:00`, "MM-DD-YYYY HH:mm:ss");
};

export const parseSearchDateTime = (date?: string, time?: string): Moment | undefined => {
  if (!date || !time) {
    return;
  }

  return parseFormattedDateTime(date, time);
};

export function normalizeDateTime(dateTime: Moment) {
  return dateTime.format("YYYY-MM-DDTHH:mm:ss");
}

export function formatStringToDate(value?: string, format = "MM/DD/YYYY") {
  return value ? moment(value, format) : undefined;
}

export function setFormElement(formElement: HTMLFormElement, name: string, value) {
  let input = formElement.elements.namedItem(name) as Element;

  if (!input) {
    const element = document.createElement("input");
    element.setAttribute("type", "hidden");
    element.setAttribute("name", name);
    formElement.appendChild(element);
    input = element;
  }

  input.setAttribute("value", value);
}
