import { captureMessage } from "@hotel-engine/utilities";
import { googleMaps } from "@hotel-engine/scripts/google";
import { throttle, once } from "lodash/fp";
import type { Dispatch, SetStateAction } from "react";

type Autocomplete = google.maps.places.AutocompleteService;
export type Response = google.maps.places.AutocompleteResponse;
type AutocompletePrediction = google.maps.places.AutocompletionRequest;

/** GooglePlacesService
 * Service to allow us to leverage google places API independently of our Google
 * AutoComplete functionaltiy (GoogleAutoCompleteService), such as getting results
 * for a specific airport based on the airport name for internal app logic vs user input.
 * In some programmatic cases, we need to bypass the throttle to perform a handful of
 * requests, for example in a loop like on the flight map. In general we should not be doing
 * this unless necessary in alternate cases like that.
 */
export default function GooglePlacesService(bypassThrottle = false) {
  const apiThrottle = throttle(bypassThrottle ? 0 : 50);

  const getService = once((api: google.maps.PlacesLibrary) => {
    return new api.AutocompleteService();
  });

  function postInput(input: string, config?: Partial<AutocompletePrediction>) {
    return (service: Autocomplete) =>
      service.getPlacePredictions({
        input,
        componentRestrictions: config?.componentRestrictions,
        types: config?.types,
      });
  }

  const errorMessage = "Google autocomplete was unable to return results for the input text";

  const request = apiThrottle(
    (
      input: string,
      setIsLoading: Dispatch<SetStateAction<boolean>>,
      setResult?: Dispatch<SetStateAction<Response>>,
      config?: Partial<AutocompletePrediction>
    ) => {
      setIsLoading(true);

      return googleMaps
        .importLibrary("places")
        .then(getService)
        .then(postInput(input, config))
        .then((res) => {
          setIsLoading(false);
          setResult?.(res);
          return res;
        })
        .catch((error) => {
          setIsLoading(false);
          captureMessage(errorMessage, { error, input }, "info");
        });
    }
  );

  return {
    request: (
      input: string,
      setIsLoading: Dispatch<SetStateAction<boolean>>,
      setResult?: Dispatch<SetStateAction<Response>>,
      config?: Partial<AutocompletePrediction>
    ) => request(input, setIsLoading, setResult, config),
  };
}
