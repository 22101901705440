import { useCallback, Fragment, type KeyboardEvent } from "react";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { Icon, Box, TopNavBadge, Button, Typography } from "@hotelengine/atlas-web";

import { useSupportCaseUpdatesCountQuery } from "@hotel-engine/react-query/supportCenter/useSupportCaseUpdatesCountQuery";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { User } from "@hotel-engine/services";
import { useAppDispatch } from "store/hooks";
import { useUser } from "@hotel-engine/hooks";
import { AuthActions } from "store/Auth/AuthRedux";
import { UserPrefsActions } from "store/UserPrefs/UserPrefsRedux";
import { Unsafe } from "@hotel-engine/data";
import { handleSignOut } from "../../../helpers";
import { InternalUserSettings } from "../InternalUserSettings";
import { supportCenterRouteSources, routes } from "@hotel-engine/constants";
import { getUserNavLinks, hePerkBusinessId } from "../../../constants";
import type { IHeaderFooterProps } from "../types";

import {
  DropdownFooter,
  StyledDropdownItem,
  BadgeWrapper,
  HeaderCTAWrapper,
  Ul,
  Li,
  HeaderCTAItem,
  SignOutButton,
} from "./styles";

export const HeaderFooter = ({
  onToggleBizPersonalAccount,
  userTokenMode,
  canCreatePersonalAccount,
  shouldShowMyProperties,
  shouldShowReferAFriend,
  shouldShowTravelCredits,
  showSwitch,
  onInviteTraveler,
  shouldShowRewards,
  onPersonalAccountCreation,
  isMobile,
}: IHeaderFooterProps) => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const navigate = useNavigate();
  const currentUser = new User(user);
  const hasCoordinatorAccess = currentUser.hasRole("coordinator");
  const supportCenterFeatureOn = useIsFeatureFlagOn("support-center");
  const { data, refetch } = useSupportCaseUpdatesCountQuery();
  const supportCaseUpdatesCount = data?.supportCaseUpdatesCount ?? 0;

  const signOut = useCallback(() => dispatch(AuthActions.signOut()), [dispatch]);

  const handleSupportCaseClick = useCallback(() => {
    const cb = setTimeout(async () => {
      refetch().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }, 3000);

    navigate(`${routes.supportCenter}?source=${supportCenterRouteSources.dropDown}`);

    return () => {
      clearTimeout(cb);
    };
  }, [refetch, navigate]);

  const onToggleBizPersonalAccountKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        onToggleBizPersonalAccount();
      }
    },
    [onToggleBizPersonalAccount]
  );

  const onSignOutClick = useCallback(async () => {
    await handleSignOut(navigate, signOut);
    dispatch(UserPrefsActions.clearUserPrefs());
  }, [dispatch, navigate, signOut]);

  const onSignOutKeyDown = useCallback(
    async (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        await onSignOutClick();
      }
    },
    [onSignOutClick]
  );

  const { business } = user;
  const isInviteTravelerEnabled =
    hasCoordinatorAccess && (business.id === hePerkBusinessId || !business.isInternal);

  const userNavLinks = getUserNavLinks({
    navigate,
    shouldShowReferAFriend,
    shouldShowMyProperties,
    shouldShowRewards,
    shouldShowTravelCredits,
    onInviteTraveler,
    // this is moving to a button in new design, link still needed in old dropdown, but now new, can remove once old header sunsets
    showSwitch: false,
    canCreatePersonalAccount,
    userTokenMode,
    onToggleBizPersonalAccount,
    isInviteTravelerEnabled,
    supportCenterFeatureOn,
    handleSupportCaseClick,
    onPersonalAccountCreation,
    isRedesign: true,
  });

  if (!isMobile) {
    return (
      <DropdownFooter>
        {userNavLinks.map(({ label, onClick, icon, shouldShow }) => (
          <Fragment key={label}>
            {!!shouldShow && (
              <>
                <StyledDropdownItem onClick={() => onClick(user)} trailingIcon={icon}>
                  <Typography variant="body/md">{label}</Typography>
                  {label === "Support Center" && !!supportCaseUpdatesCount && (
                    <BadgeWrapper>
                      <TopNavBadge
                        text={
                          supportCaseUpdatesCount < 100 ? String(supportCaseUpdatesCount) : "99+"
                        }
                      />
                    </BadgeWrapper>
                  )}
                </StyledDropdownItem>
              </>
            )}
          </Fragment>
        ))}
        <HeaderCTAWrapper>
          {!!showSwitch && (
            <HeaderCTAItem onKeyDown={onToggleBizPersonalAccountKeyDown}>
              <Button onClick={onToggleBizPersonalAccount} color="everlight">
                Switch to {userTokenMode === "business" ? "personal" : "business"}
              </Button>
            </HeaderCTAItem>
          )}
          <HeaderCTAItem
            onKeyDown={onSignOutKeyDown}
            style={{ flexBasis: !showSwitch ? "100%" : undefined }}
          >
            <SignOutButton onClick={onSignOutClick} variant="plain" color="everlight">
              Sign out
            </SignOutButton>
          </HeaderCTAItem>
        </HeaderCTAWrapper>
        <InternalUserSettings isInternalUser={user.business.isInternal} />
      </DropdownFooter>
    );
  }

  return (
    <Box>
      <Ul>
        {userNavLinks.map(({ label, onClick, icon, shouldShow }) => (
          <Fragment key={label}>
            {!!shouldShow && (
              <Li>
                <Box
                  onClick={() => onClick(user)}
                  display="flex"
                  justifyContent="space-between"
                  padding={12}
                >
                  <Box flexDirection="row" display="flex" alignItems="center" gap={16}>
                    <Typography variant="body/md" color="foregroundInverse">
                      {label}
                    </Typography>
                    {label === "Support Center" && !!supportCaseUpdatesCount && (
                      <BadgeWrapper>
                        <TopNavBadge
                          text={
                            supportCaseUpdatesCount < 100 ? String(supportCaseUpdatesCount) : "99+"
                          }
                        />
                      </BadgeWrapper>
                    )}
                  </Box>
                  <Icon name={icon} />
                </Box>
              </Li>
            )}
          </Fragment>
        ))}
      </Ul>
      <HeaderCTAWrapper>
        <div>
          {!!showSwitch && (
            <Button onClick={onToggleBizPersonalAccount} color="everlight">
              Switch to {userTokenMode === "business" ? "personal" : "business"}
            </Button>
          )}
        </div>
        <div style={{ flexBasis: !showSwitch ? "100%" : undefined }}>
          <SignOutButton onClick={onSignOutClick} variant="plain" color="everlight">
            Sign out
          </SignOutButton>
        </div>
      </HeaderCTAWrapper>
      <InternalUserSettings isMobile={isMobile} isInternalUser={user.business.isInternal} />
    </Box>
  );
};
