import styled from "styled-components";

// This is a common component, but not a wrapper on an AntD component
import Image from "@hotel-engine/common/Image";
// We don't yet have an Atlas replacement for this, so in the meantime we will continue to use the AntD component and make sure all styles applied utilize Atlas tokens
import { Table } from "@hotel-engine/common/Table";
import { ellipsisText } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";

export const TripType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const TripDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:${({ theme }) => theme.spacing[8]};
`;

export const ImageContainer = styled.div`
    height: ${({ theme }) => theme.spacing[32]};
    width: ${({ theme }) => theme.spacing[32]};
    `;

export const CarImageContainer = styled.div`
height: "auto";
width: ${({ theme }) => theme.spacing[32]};

`;

export const CarTripImage = styled(Image)`
  object-fit: contain;
  height: "auto";
  width: ${({ theme }) => theme.spacing[32]};
  max-width: ${({ theme }) => theme.spacing[32]};
`;

export const TripImage = styled(Image)`
  border-radius: ${({ theme }) => theme.borderRadius[4]};
  object-fit: contain;
  height: "auto";
  width: ${({ theme }) => theme.spacing[32]};
  max-width: ${({ theme }) => theme.spacing[32]};
`;

export const DesktopContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: hidden;
`;

export const TableContainer = styled.div`
  .Table_table-wrapper {
    padding-bottom: ${({ theme }) => theme.spacing[0]};
  }
`;

export const ListViewTable = styled(Table)`
  thead {
    tr {
      th:first-of-type {
        border-left: ${({ theme }) =>
          `${theme.borderWidth.normal} solid ${theme.colors.neutralN100}`};
      }

      th:last-of-type {
        border-right: ${({ theme }) =>
          `${theme.borderWidth.normal} solid ${theme.colors.neutralN100}`};
      }

      th {
        background-color: ${({ theme }) => theme.colors.backgroundTertiary} !important;
        border-top: ${({ theme }) =>
          `${theme.borderWidth.normal} solid ${theme.colors.neutralN100}`};
        border-bottom: ${({ theme }) =>
          `${theme.borderWidth.normal} solid ${theme.colors.neutralN100}`};
        > span {
          width: 100%;
        }

       .start_time-header:hover, .end_time-header:hover, .price-header:hover {
            cursor: pointer;

            span {
                color: ${({ theme }) => theme.colors.foregroundSecondary};
            }
        }


      }
    }
  }

  tbody {
    tr {
      td:first-of-type {
        border-left: ${({ theme }) =>
          `${theme.borderWidth.normal} solid ${theme.colors.neutralN100}`};
      }
      td:last-of-type {
        border-right: ${({ theme }) =>
          `${theme.borderWidth.normal} solid ${theme.colors.neutralN100}`};
      }
    }

    tr:last-of-type {
      td:first-of-type {
        border-bottom-left-radius: ${({ theme }) => theme.borderRadius.md};
      }
      td:last-of-type {
        border-bottom-right-radius: ${({ theme }) => theme.borderRadius.md};
      }
    }
  }

  .ant-table-thead > tr > th {
    padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[8]}`};

    .ant-table-header-column {
      vertical-align: middle;
    }
  }

  .ant-table-tbody > tr > td {
      padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[8]}`};
      background-color: ${({ theme }) => theme.colors.backgroundPrimary};

    }

  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child {
    padding-left: ${({ theme }) => theme.spacing[16]};
  }

  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.md};
  }

  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: ${({ theme }) => theme.borderRadius.md};
  }

  .start-col > div,
  .end-col > div {
    text-overflow: unset;
  }

  .type-col,
  .details-col,
  .travelers-col,
  .price-col {
    height: ${({ theme }) => theme.spacing[40]};
    padding: ${({ theme }) => theme.spacing[16]};
    margin: ${({ theme }) => theme.spacing[0]};
  }

  .start-col,
  .end-col {
    width: 8%;
  }

  .location-col {
    width: 14%;
  }

  .travelers-col {
    width: 20%;
  }

  .price-col {
    width: 12%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .selected-row > td {
    background-color: ${({ theme }) => theme.colors.accentGreenLightest} !important;
  }

  .ant-table-tbody > tr:hover td {
    background: ${({ theme }) => theme.colors.backgroundPrimaryHover} !important;
  }
`;

export const PrimaryText = styled<React.FC<TypographyProps>>(Typography).attrs({
  variant: "body/sm",
  color: "foregroundPrimary",
})`
  ${ellipsisText};
`;

export const SubText = styled<React.FC<TypographyProps>>(Typography).attrs({
  variant: "body/xs",
  color: "foregroundPrimary",
})<{
  isSuccess?: boolean;
  $isVerified?: boolean;
}>`
  ${ellipsisText};
  display: block;
  color: ${({ theme, isSuccess, $isVerified }) =>
    isSuccess || $isVerified ? theme.colors.accentGreenLight : theme.colors.neutralN500};
`;

export const LoadMore = styled.div`
  ${flexbox({
    justifyContent: "center",
  })}
`;

export const TripsColumnHeader = styled.div<{ sortHeader: boolean }>`
    display: flex;
    align-items: center;

    span {
        display: flex;
        justify-content: center;
    }

  span:last-child {
    margin-left: ${({ theme }) => theme.spacing[8]};
    display: ${({ sortHeader }) => !sortHeader && "none"};
  }
`;
