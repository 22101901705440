import { useMemo, type ReactNode } from "react";

import { AirlineLogo } from "@hotel-engine/assets/flights/AirlineLogo";
import { Avatar, Box, Icon, Typography } from "@hotelengine/atlas-web";
import type { IFlightItinerary } from "@hotel-engine/types/itinerary";

import { useLoyaltyInfo } from "pages/Flights/hooks/useLoyaltyInfo";

import { CollapsibleContent } from "@hotel-engine/app/ItineraryContent/shared/CollapsibleContent";

import * as Styled from "./styles";

interface ITravelerInfoBlockContentProps {
  iconLogo: ReactNode;
  leftContent: ReactNode;
  rightContent: ReactNode;
}
const TravelerInfoBlockContent = ({
  iconLogo,
  leftContent,
  rightContent,
}: ITravelerInfoBlockContentProps) => {
  return (
    <Styled.TravelerInfoBlockContentGrid>
      <Box>{iconLogo}</Box>
      <Box>{leftContent}</Box>
      <Box className="right">{rightContent}</Box>
    </Styled.TravelerInfoBlockContentGrid>
  );
};

interface ITravelerInfoProps {
  reservation: IFlightItinerary;
  padTop?: boolean;
}

export const TravelerInfo = ({ reservation, padTop = true }: ITravelerInfoProps) => {
  const { getLoyaltyNameByIataAirlineCode } = useLoyaltyInfo();

  const travelerDetails = reservation.travelers;

  const maskData = (data: string | null) => {
    if (!data) return null;

    const visible = data?.substring(data.length - 2);
    const dots = Array(data.length)
      .map(() => "•")
      .join();
    return `${dots}${visible}`;
  };

  const travelerCollapsibles = useMemo(() => {
    return travelerDetails.map((traveler, travelerIdx) => {
      return (
        <CollapsibleContent
          key={`${traveler.fullName}__${travelerIdx}`}
          variant="sm"
          toggleBtnLabel={
            <Box display="flex" gap={12} alignItems="center">
              <Avatar name={traveler.fullName} />
              <Typography variant="body/md">{traveler.fullName}</Typography>
            </Box>
          }
        >
          <Box display="flex" flexDirection="column">
            <Styled.TravelerInfoBlock>
              <TravelerInfoBlockContent
                iconLogo={<Icon name="circle-info" color="foregroundPrimary" />}
                leftContent={
                  <Typography variant="body/sm" color="foregroundPrimary">
                    {/* TODO: Using traveler name for now until email is available on this response */}
                    {traveler.fullName}
                  </Typography>
                }
                rightContent={
                  <Typography variant="body/sm" color="foregroundTertiary">
                    {traveler.type}
                  </Typography>
                }
              />
            </Styled.TravelerInfoBlock>
            {!!traveler.knownTravelerNumber ||
              (!!traveler.redressNumber && (
                <Styled.TravelerInfoBlock>
                  <Box display="flex" flexDirection="column" gap={16}>
                    {!!traveler.knownTravelerNumber && (
                      <TravelerInfoBlockContent
                        iconLogo={<Icon name="shield-check" color="foregroundPrimary" />}
                        leftContent={
                          <Typography variant="body/sm" color="foregroundPrimary">
                            Known Traveler Number (TSA&nbsp;PreCheck)
                          </Typography>
                        }
                        rightContent={
                          <Typography variant="body/sm" color="foregroundPrimary">
                            {maskData(traveler.knownTravelerNumber)}
                          </Typography>
                        }
                      />
                    )}
                    {!!traveler.redressNumber && (
                      <TravelerInfoBlockContent
                        iconLogo={<Icon name="eye" color="foregroundPrimary" />}
                        leftContent={
                          <Typography variant="body/sm" color="foregroundPrimary">
                            Redress number
                          </Typography>
                        }
                        rightContent={
                          <Typography variant="body/sm" color="foregroundPrimary">
                            {maskData(traveler.redressNumber)}
                          </Typography>
                        }
                      />
                    )}
                  </Box>
                </Styled.TravelerInfoBlock>
              ))}

            {!!traveler.loyaltyNumber.filter((rewardsProgram) => rewardsProgram.memberId)
              .length && (
              <Styled.TravelerInfoBlock>
                <Box display="flex" flexDirection="column" gap={16}>
                  {traveler.loyaltyNumber
                    .filter((rewardsProgram) => rewardsProgram.memberId)
                    .map((rewardsProgram) => {
                      return (
                        <TravelerInfoBlockContent
                          key={`traveler__${travelerIdx}__rewards__${rewardsProgram.iataAirlineCode}`}
                          iconLogo={<AirlineLogo iata={rewardsProgram.iataAirlineCode} />}
                          leftContent={
                            <Typography variant="body/sm" color="foregroundPrimary">
                              {getLoyaltyNameByIataAirlineCode(rewardsProgram.iataAirlineCode)}
                            </Typography>
                          }
                          rightContent={
                            <Typography variant="body/sm" color="foregroundPrimary">
                              {rewardsProgram.memberId}
                            </Typography>
                          }
                        />
                      );
                    })}
                </Box>
              </Styled.TravelerInfoBlock>
            )}
          </Box>
        </CollapsibleContent>
      );
    });
  }, [getLoyaltyNameByIataAirlineCode, travelerDetails]);

  return (
    <Styled.TravelerCollapsibleContainer $padTop={padTop}>
      {travelerCollapsibles}
    </Styled.TravelerCollapsibleContainer>
  );
};
