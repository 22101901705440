import styled, { css } from "styled-components";
import { flexbox } from "../../styles/helpers/mixins";
import _Loader from "@hotel-engine/common/Loader";
import { Box, Divider, type BoxProps } from "@hotelengine/atlas-web";

const PaddedItineraryContainer = styled<React.FC<BoxProps>>(Box)<{
  $isPreview: boolean;
}>`
  padding-left: ${({ theme, $isPreview }) => theme.spacing[$isPreview ? 24 : 40]};
  padding-right: ${({ theme, $isPreview }) => theme.spacing[$isPreview ? 24 : 40]};
  position: relative;
  `;

const PaddedScrollSectionContainer = styled<React.FC<BoxProps>>(Box)<{
  $isPreview: boolean;
}>`
  padding-bottom: ${({ $isPreview, theme }) =>
    !$isPreview ? theme.spacing[40] : theme.spacing[24]};
  display: flex;
  flex-direction: column;
`;

/* This will allow us to keep a consistent padding around the container
 * for the rest of the components but ensure that the divider will span
 * the whole container on both views/pages */
//  TODO: remove this in favor of using the Divider from atlas
const SectionDivider = styled(Divider)`
  color: ${({ theme }) => theme.colors.borderMinimal};
  margin-left: -40px;
  width: calc(100% + 80px);
  overflow: hidden;

  ${({ theme }) => {
    return css`
      @media screen and (max-width: ${theme.breakpoints.md}) {
        margin-left: -24px;
        width: calc(100% + 48px);
      }
    `;
  }}
`;

const SuccessWrapperContainer = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: ${({ theme }) =>
    `${theme.borderWidth.normal} solid ${theme.colors.sentimentPositiveDefault}`};
`;

const Loader = styled(_Loader)`
  ${flexbox({
    direction: "column",
  })}
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  padding-top: ${({ theme }) => theme.spacing[64]};
`;

export {
  Loader,
  PaddedItineraryContainer,
  PaddedScrollSectionContainer,
  SectionDivider,
  SuccessWrapperContainer,
};
