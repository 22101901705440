import { useFormatDate } from "@hotel-engine/hooks/useFormatDate";
import type { INotificationItem } from "@hotel-engine/types/courierNotifications";

import * as SharedStyled from "../styles";
import NotificationActions from "./NotificationActions";
import { NotificationLink } from "./NotificationLink";
import * as Styled from "./styles";
import { returnIcon } from "./utils";
import {
  Box,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
} from "@hotelengine/atlas-web";

const NotificationItem = ({
  markMessageArchived,
  markMessageRead,
  markMessageUnread,
  message: {
    blocks,
    created,
    messageId,
    read,
    data,
    trackingIds: { archiveTrackingId, readTrackingId, unreadTrackingId },
  },
  isRedesign24,
}: INotificationItem & { isRedesign24?: boolean }) => {
  const handleMessageStatus = () => {
    if (read) {
      markMessageUnread(messageId, unreadTrackingId);
    } else {
      markMessageRead(messageId, readTrackingId);
    }
  };

  const handleArchiveMessage = () => {
    markMessageArchived(messageId, archiveTrackingId);
  };

  const handleNotificationAction = () => {
    markMessageRead(messageId, readTrackingId);
  };

  return (
    <Styled.Notification data-testid={messageId}>
      <Box display="flex" flexDirection="row">
        <Styled.NotificationIconWrap category={data?.category} $isRedesign24={isRedesign24}>
          {returnIcon(data?.category, isRedesign24)}
          {!read && <SharedStyled.Badge />}
        </Styled.NotificationIconWrap>

        <Styled.NotificationBody>
          {blocks.map((block) => {
            const { text } = block;
            return (
              <Typography as="p" variant="body/sm" key={text} marginBottom={2}>
                {text}
              </Typography>
            );
          })}

          <NotificationLink {...data} handleNotificationAction={handleNotificationAction} />
          <Typography as="p" variant="body/xs" marginBottom={0}>
            {useFormatDate(created, "MMM D")}
          </Typography>
        </Styled.NotificationBody>
      </Box>

      <Popover>
        <PopoverTrigger>
          <Box>
            <Icon name="ellipsis" style={{ cursor: "pointer" }} />
          </Box>
        </PopoverTrigger>
        <PopoverContent align="end" style={{ maxWidth: "unset" }}>
          <NotificationActions
            handleMessageStatus={handleMessageStatus}
            handleArchiveMessage={handleArchiveMessage}
            read={read}
          />
        </PopoverContent>
      </Popover>
    </Styled.Notification>
  );
};

export default NotificationItem;
