import { useState } from "react";
import { ampli } from "ampli";

import * as Styled from "../../../../styles";
import { useAppSelector } from "store/hooks";
import { selectSearchId } from "store/Search/SearchResults/selectors";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

type MapTypeToggleProps = {
  updateMapStyle: (val: string) => void;
};

const MapTypeToggle = ({ updateMapStyle }: MapTypeToggleProps) => {
  const [isBasicMap, setIsBasicMap] = useState(true);
  const searchId = useAppSelector(selectSearchId) ?? undefined;
  const rebrandFlagOn = useIsFeatureFlagOn("rebrand_logo_copy");

  const handleStyleClick = (mapType: string) => {
    if (isBasicMap) {
      ampli.clickSatelliteView({ searchId });
    }
    updateMapStyle(mapType);
    setIsBasicMap((prev) => !prev);
  };

  const buttonVal = isBasicMap ? "Satellite" : "Basic";

  if (rebrandFlagOn) {
    return (
      <Styled.GoogleMapControlButtonRebrand
        aria-label={buttonVal}
        data-testid="map-type-toggle"
        id={buttonVal}
        onClick={() => handleStyleClick(isBasicMap ? "satellite" : "roadmap")}
      >
        {buttonVal}
      </Styled.GoogleMapControlButtonRebrand>
    );
  }

  return (
    <Styled.GoogleMapControlButton
      aria-label={buttonVal}
      data-testid="map-type-toggle"
      id={buttonVal}
      onClick={() => handleStyleClick(isBasicMap ? "satellite" : "roadmap")}
    >
      {buttonVal}
    </Styled.GoogleMapControlButton>
  );
};
export default MapTypeToggle;
