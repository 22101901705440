import { useCallback, useEffect } from "react";
import ItineraryContent from "@hotel-engine/app/ItineraryContent";
import { useItineraryQuery } from "@hotel-engine/react-query/itinerary/useItineraryQuery";
import type { IFlightItinerary } from "@hotel-engine/types/itinerary";

import type { ViewType } from "pages/Trips/constants";
import { viewTypes } from "pages/Trips/constants";

import * as Styled from "../styles";
import type { ITripPreview } from "../../../context";
import { useTripsContext } from "../../../context";

export interface IFlightItineraryWithId extends IFlightItinerary {
  tripId: number;
  bookingNumber: string;
}

export interface ITripsItineraryPreviewPanelProps {
  handleView?: (viewType: ViewType) => void;
  itineraryId: string | number;
  onClose: (tripPreview: ITripPreview) => void;
  view: "map" | "list";
}

const FlightItineraryPanel = ({
  handleView,
  onClose,
  itineraryId,
  view,
}: ITripsItineraryPreviewPanelProps) => {
  const { tripsRefresh } = useTripsContext();

  const { data, isError, isLoading } = useItineraryQuery({
    id: Number(itineraryId),
  });

  const tripItinerary = {
    tripId: itineraryId,
    bookingNumber: data?.bookings[0].bookingNumber,
    ...data?.bookings?.[0]?.details,
  } as IFlightItineraryWithId;

  const handleScroll = useCallback(() => {
    const panel = document.getElementById("preview-panel");

    if (!!panel) {
      panel.style.height = `calc(100vh - 90px + ${`${globalThis.scrollY}px`})`;
    }
  }, []);

  useEffect(() => {
    globalThis.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      globalThis.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Styled.TripsPreviewPanel id="preview-panel" data-testid="preview-panel" $isFlight>
      {!!tripItinerary && !isError && (
        <Styled.ScrollContainer
          // This id is needed for post-modification scroll to top
          id="itinerary-scroll-container"
        >
          <ItineraryContent
            isLoading={isLoading}
            itinerary={tripItinerary}
            isPreview={true}
            onClose={onClose}
            handleView={() => handleView?.(viewTypes.MAP)}
            view={view}
            refetchReservation={tripsRefresh}
            bookingType="flight"
          />
        </Styled.ScrollContainer>
      )}
    </Styled.TripsPreviewPanel>
  );
};

export default FlightItineraryPanel;
