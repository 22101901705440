import { useFlightModificationsContext } from "../../ModificationsContext";

const useIsFlightCancelled = () => {
  const { reservation } = useFlightModificationsContext();

  if (!reservation) {
    return { isFlightCancelled: undefined, isPendingCancellation: undefined };
  }

  const pendingCancellationCaseStatuses = ["NEW", "PENDING"];
  const isPendingCancellation = pendingCancellationCaseStatuses.includes(
    String(reservation.pendingModifications?.cancellation)
  );

  const isFlightCancelled = !!reservation.isFullyCancelled;

  return { isFlightCancelled, isPendingCancellation };
};

export default useIsFlightCancelled;
