import styled from "styled-components";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const ControlsWrapper = styled.header`
  padding: ${({ theme }) => theme.spacing[16]};
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.backdropAbove + 1};
  pointer-events: none;

  ${() =>
    flexbox({
      direction: "row",
      justifyContent: "space-between",
    })};
`;

export const ButtonGroup = styled.div<{
  $showUpdateSearchAreaButton;
}>`
  gap: ${({ theme }) => theme.spacing[12]};

  ${({ $showUpdateSearchAreaButton }) =>
    flexbox({
      alignItems: "flex-start",
      direction: $showUpdateSearchAreaButton ? "column-reverse" : "row",
    })};

  button {
    padding: 2px 10px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;
