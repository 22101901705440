import { useEffect, useMemo } from "react";

import { useGlobalTheme } from "@hotel-engine/contexts/GlobalThemeContext";

import AirportMarker from "../AirportMarker";
import { dedupeLocations, getAirportMarkerData } from "./helpers";

export type AirportMarkerData = {
  position: { lat: number; lng: number };
  airport: string;
  inverted: boolean;
};

/** Used to render markers for airports with a flight paths */
const FlightMarkers = ({ map, locations, isMobile }) => {
  const theme = useGlobalTheme();
  const bounds = useMemo(() => new google.maps.LatLngBounds(), []);

  /** Here we pull out any slices that would create fully duplicated pins and paths, either fully same or same but inverted locations */
  const dedupedSlices = dedupeLocations(locations);

  /** Set the bounds based on the origin and destination of each slice so that the map is at the correct zoom level to show all airports */
  dedupedSlices.forEach((marker) => {
    bounds.extend(new google.maps.LatLng(marker?.origin?.lat || 0, marker?.origin?.lng || 0));
    bounds.extend(
      new google.maps.LatLng(marker?.destination?.lat || 0, marker?.destination?.lng || 0)
    );
  });

  /** Set the bounds of the map once we have used the airport locations to extend them */
  useEffect(() => {
    if (!!map && !!bounds) {
      map.fitBounds(bounds, 75);
    }
  }, [map, bounds, isMobile]);

  /** Generate the flight path lines between the airport pins and add them to the map */
  useEffect(() => {
    if (!!map) {
      const dot = {
        path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 1,
        strokeOpacity: 1,
        strokeWeight: isMobile ? 1 : 2,
        fillColor: theme.tokens.colors.backgroundPrimary,
        strokeColor: theme.tokens.colors.neutralN800,
        scale: isMobile ? 3 : 4,
      };

      dedupedSlices.forEach((slice) => {
        const flightPath = new google.maps.Polyline({
          path: [slice.origin || { lat: 0, lng: 0 }, slice.destination || { lat: 0, lng: 0 }],
          geodesic: true,
          strokeColor: theme.tokens.colors.neutralN800,
          strokeOpacity: 1.0,
          strokeWeight: 2,
          icons: [
            {
              icon: dot,
              offset: "0%",
            },
            {
              icon: dot,
              offset: "100%",
            },
          ],
        });

        flightPath.setMap(map);
      });
    }
  }, [
    dedupedSlices,
    isMobile,
    map,
    theme.tokens.colors.backgroundPrimary,
    theme.tokens.colors.neutralN800,
  ]);

  const airportMarkerData = getAirportMarkerData(dedupedSlices);

  const airportMarkers = airportMarkerData.map((data) => (
    <AirportMarker
      key={data.airport}
      map={map}
      position={data.position}
      airport={data.airport}
      inverted={data.inverted}
    />
  ));

  return <>{airportMarkers}</>;
};

export default FlightMarkers;
