import styled, { createGlobalStyle, css } from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { Button } from "@hotelengine/atlas-web";

import { Tag } from "./Tag/styles";

interface IButtonGroup {
  columnGap?: "xsmall" | "small" | "normal";
}

const iconButton = css`
  &:last-child {
    margin-right: ${({ theme }) => theme.spacing[0]};
  }

  & > ${Tag}, & > .icon {
    margin-left: ${({ theme }) => theme.spacing[8]};
  }
`;

export const MobileSearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[8]};
    `;

export const FiltersDot = styled.div`
    width: 6px;
    height: 6px;
    position: absolute;
    top: -2px;
    right: 4px;
    background-color: ${({ theme }) => theme.colors.foregroundPrimary};
    border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.foregroundInverse}`};
    border-radius: ${({ theme }) => theme.borderRadius.circle};
`;

export const TripsFiltersContainer = styled.div<{ $isMobile: boolean }>`
    margin: ${({ theme }) => theme.spacing[0]};
    margin-bottom: ${({ theme, $isMobile }) => theme.spacing[$isMobile ? 0 : 24]};
`;

export const TripsFilters = styled.div`
  ${flexbox({
    justifyContent: "space-between",
  })}


  & > button {
    ${iconButton};
  }

  ${({ theme }) =>
    css`
        @media screen and (min-width: ${theme.breakpoints.xl}) {
            align-items: baseline;
        }
  `}
`;

const ButtonGroup = styled.div<IButtonGroup>`
  ${flexbox({ alignItems: "center" })}
  column-gap: ${({ theme, columnGap }) =>
    columnGap === "xsmall"
      ? theme.spacing[2]
      : columnGap === "small"
        ? theme.spacing[8]
        : theme.spacing[20]};

  & button.status-button:hover:enabled,
  & button.status-button:focus,
  & button.status-button:focus:disabled {
    color: initial;
    border-color: initial;
  }

  & .ant-checkbox-wrapper {
    overflow: hidden;
  }

  & .ant-checkbox-wrapper span {
    padding: ${({ theme }) => theme.spacing[0]};
  }

  & .ant-checkbox-wrapper span.ant-checkbox {
    padding: ${({ theme }) => theme.spacing[0]};
    margin-right: ${({ theme }) => theme.spacing[8]};
  }

  & #trips_filterDropdown,
  & .ant-calendar-picker {
    width: fit-content;
    transition-property: all;
    transition-timing-function: ease;
  }

  & .ant-input-affix-wrapper {
    width: 168px;
    height: 32px;
    > input {
      border-color: ${({ theme }) => theme.colors.neutralN300};
    }
    :hover {
      > input {
        border-color: ${({ theme }) => theme.colors.accentBlue};
      }
    }
  }

  & #searchTrips {
    padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[8]}`};
  }

  & #trips_filterDropdown,
  & .ant-calendar-picker-input,
  & #searchTrips {
    height: 32px;
    border-radius: ${({ theme }) => theme.borderRadius.md};
    border-width: ${({ theme }) => theme.borderWidth.normal}
  }

    #trips_datesPicker span {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.foregroundPrimary};
        border-color: ${({ theme }) => theme.colors.borderSubtle};
        background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    
    &:hover {
        border-color: ${({ theme }) => theme.colors.foregroundEverdark};
        color: ${({ theme }) => theme.colors.foregroundSecondary};
    }

    .ant-calendar-picker-input {
        &::placeholder {
            color: ${({ theme }) => theme.colors.accentBlue} !important;
        }

        &:hover {
        border-color: ${({ theme }) => theme.colors.foregroundEverdark};
        color: ${({ theme }) => theme.colors.foregroundPrimary};
    }
    }
  }

  & #trips_filterDropdown,
  & .ant-calendar-picker-input {
    padding: 0 15px;
  }

  & #trips_filterDropdown > img,
  & .ant-calendar-picker-icon {
    position: relative;
    width: 16px;
    height: 16px;
  }

  & .ant-calendar-picker-icon {
    top: 3px;
    right: initial;
  }

  & #trips_filterDropdown,
  & .ant-calendar-picker-input.ant-input {
    ${flexbox({ alignItems: "center", direction: "row-reverse" })}
    column-gap: ${({ theme }) => theme.spacing[8]};
  }

  ${({ theme }) =>
    css`
        @media screen and (max-width: ${theme.breakpoints.xl}) {
            margin-top: ${theme.spacing[12]}
        }
  `}
`;

const StatusButton = styled(Button).attrs({
  size: "sm",
  variant: "plain",
  color: "secondary",
})`
  height: 36px;
  border: none;
  padding: ${({ theme }) => theme.spacing[8]};
  border-radius: 0;

  &:not(.active):hover,
  &.active {
    padding-bottom: ${({ theme }) => theme.spacing[6]};
  }

  &:not(.active):hover,
  &:hover:not(:disabled) {
    background: transparent;
  }

  &:hover,
  &.active {
    border-bottom: ${({ theme }) => `${theme.borderWidth.wide} solid ${theme.colors.neutralN800}`};
    border-radius: 0;
    &[ant-click-animating-without-extra-node="true"]::after {
      display: none;
    }
  }
`;

export const RangePickerGlobalStyles = createGlobalStyle`
  .ant-calendar-input-wrap,
  .ant-calendar-range-middle {
    display: none;
  }

  .ant-input,
  .ant-input::placeholder,
  .ant-calendar-picker-icon {
    color: rgba(0, 0, 0, 0.65);
    height: 40px;
  }

  .ant-calendar-picker-input {
    height: 40px;

    &:hover {
      color: ${({ theme }) => theme.colors.foregroundSecondary};
      border-color: ${({ theme }) => theme.colors.foregroundSecondary};
      & svg {
        color: ${({ theme }) => theme.colors.foregroundSecondary};
      }

      i,
      .ant-calendar-range-picker-input,
      input {
        color: ${({ theme }) => theme.colors.foregroundSecondary};

        &::placeholder {
          color: ${({ theme }) => theme.colors.foregroundSecondary};
        }
      }
    }
  }

  .ant-calendar-range-picker-separator {
    display: none;
  }

  .ant-calendar-range-picker-input {
    cursor: pointer;
    width: 100%;
    text-align: left;
    padding-bottom: 0.5px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-picker-input > :nth-child(3) {
    display: none;
  }
`;

export { ButtonGroup, StatusButton };
