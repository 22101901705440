import { useState } from "react";

import { Formik } from "formik";

import Button from "@hotel-engine/common/Button";
import TextAreaField from "@hotel-engine/common/FormikFields/TextAreaField";
import { notification } from "@hotel-engine/common/Notifications";
import { useBulkMembersMutation } from "@hotel-engine/react-query/members/useBulkMembersMutation";
import { isIBatchError, isIErrorResponse } from "@hotel-engine/types/errors";
import { isIE11 } from "@hotel-engine/utilities";
import { captureMessage } from "@hotel-engine/utilities/logger";
import config from "config";
import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";
import { Unsafe } from "@hotel-engine/data";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

export const InviteUserModal = ({
  onCancel,
  visible,
}: {
  onCancel: (resetForm: () => void) => void;
  visible: boolean;
}) => {
  const business = useAppSelector((state) => state.Auth.user?.business);
  const { COMPANY_URL } = useCompanyName();
  const shareLink = `https://www.${COMPANY_URL}/join/${business?.code}`;
  const { mutateAsync, reset } = useBulkMembersMutation({ method: "post" });
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const initialValues = {
    emails: "",
  };

  const handleInviteUsers = async (values, { setFieldError, resetForm }) => {
    try {
      const addresses = values.emails.split(",").map((a) => ({
        email: a.trim(),
        employeeId: "",
        role: "user",
      }));
      await mutateAsync(addresses);

      notification.open({
        message: "Success!",
        description:
          addresses.length > 1 ? `${addresses.length} user invites sent.` : "User invite sent",
      });

      return onCancel(resetForm);
    } catch (error) {
      captureMessage("Bulk user invite error", { error });
      if (isIErrorResponse(error)) {
        const errorType = error?.errors?.[0];
        if (isIBatchError(errorType)) {
          let errorReason = errorType.invalidParams?.[0].reasons[0];
          // Capitalize first letter of error phrase
          if (errorReason) errorReason = errorReason[0].toUpperCase() + errorReason.substring(1);
          setFieldError("emails", errorReason || [config.defaultErrorMessage]);
        }
      }
    } finally {
      reset();
    }
  };

  const handleCopyShareLink = () => {
    if (isLinkCopied) return;

    try {
      if (isIE11) {
        globalThis.clipboardData.setData("Text", shareLink);
      } else {
        navigator.clipboard.writeText(shareLink).then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
      }
      setIsLinkCopied(true);
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 3000);
    } catch (error) {
      // shareLink is a full url (like https://engine/com/join/abc123),
      // so we can't pass that to navigate().
      // I'm going to leave this current behavior of opening a new tab,
      // but it seems to me that if this current user, who is already logged in,
      // opens a new tab with this `/join` url, they will just be redirected
      // back to the members dashboard, having lost the original url to be copied.
      // The url would only function for another visitor, who isn't already signed in.
      // So this is probably not the best fallback behavior.
      globalThis.open(shareLink, "_blank");
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleInviteUsers}>
      {({ isSubmitting, handleSubmit, values, resetForm }) => (
        <Styled.InviteUserModal
          footer={null}
          title={null}
          visible={visible}
          onCancel={() => onCancel(resetForm)}
        >
          <Styled.ModalSectionHeading>Invite new members</Styled.ModalSectionHeading>
          <Styled.ModalBody>
            <div>
              <Styled.CopyLabel>Share your link, click to copy</Styled.CopyLabel>
              <Styled.CopyLink
                data-testid="share-link"
                onClick={handleCopyShareLink}
                isLinkCopied={isLinkCopied}
              >
                {!isLinkCopied ? (
                  <>
                    {shareLink} <span>Copy Link</span>
                  </>
                ) : (
                  <>
                    Link copied to clipboard<span>Copy Link</span>
                  </>
                )}
              </Styled.CopyLink>
            </div>
            <Styled.OrDivider>
              <Styled.OrText>or</Styled.OrText>
            </Styled.OrDivider>
            <p>Invite members by email</p>

            <Styled.EmailField
              component={TextAreaField}
              name="emails"
              placeholder="Separate multiple emails with a comma"
            />
          </Styled.ModalBody>
          <Styled.FooterButtons>
            <Styled.CancelButton
              id="cancel"
              data-testid="cancel-button"
              disabled={isSubmitting}
              onClick={() => onCancel(resetForm)}
            >
              Cancel
            </Styled.CancelButton>
            <Button
              id="invite"
              data-testid="invite-button"
              type="primary"
              loading={isSubmitting}
              disabled={!values.emails}
              onClick={() => handleSubmit()}
            >
              Invite Members
            </Button>
          </Styled.FooterButtons>
        </Styled.InviteUserModal>
      )}
    </Formik>
  );
};

export default InviteUserModal;
