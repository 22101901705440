import { Box } from "@hotelengine/atlas-web";
import ModificationStatusModal from "@hotel-engine/app/ItineraryContent/shared/ModficationViews/components/ModificationStatusModal";
import ModificationViews from "@hotel-engine/app/ItineraryContent/shared/ModficationViews";
import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useBreakpoint } from "@hotel-engine/hooks";
import GoogleFlightMap from "@hotel-engine/app/GoogleMap/components/GoogleFlightMap";

import FlightSlices from "../FlightSlices";
import SummaryOfCharges from "../SummaryOfCharges";
import AdditionalInfo from "../AdditionalInfo";

import { useNavigationBlocker } from "../../../shared/ModficationViews/components/NavigationModal/useNavigationBlocker";
import {
  PaddedItineraryContainer,
  SectionDivider,
} from "@hotel-engine/app/ItineraryContent/styles";
import RefundBreakdowns from "../RefundBreakdowns";
import FlightInfo from "../FlightInfo";
import ManageFlight from "../ManageFlight";
import { TravelerInfo } from "../TravelerInfo";
import { MissingDetails } from "./MissingDetails";

const FlightItineraryContentBody = () => {
  const isMobile = useBreakpoint("md", "max");

  const {
    state: { modificationView, showStatusModal },
    reservation,
    isPreview,
  } = useFlightModificationsContext();
  const shouldShowPreviewMap = (isPreview || isMobile) && !modificationView;
  const shouldShowRefundBreakdowns = !!reservation.refundBreakdowns.length;

  useNavigationBlocker();

  if (!reservation?.slices) return <MissingDetails />;

  /** This component makes up the body of the ItineraryContent that we will replace with "internal" modification flows.
   * When a dispatch is made from the ModificationsContext to update ModificationViews (defaults to undefined) we will
   * render the appropriate modification flow instead of the body content.
   */
  return (
    <>
      {!!showStatusModal && <ModificationStatusModal />}

      {!!modificationView ? (
        <Box backgroundColor="backgroundSecondary">
          <PaddedItineraryContainer $isPreview={isPreview || isMobile}>
            <Box style={{ minHeight: "100vh" }}>
              <ModificationViews />
            </Box>
          </PaddedItineraryContainer>
        </Box>
      ) : (
        <>
          <Box backgroundColor="backgroundSecondary">
            <PaddedItineraryContainer $isPreview={isPreview || isMobile}>
              <FlightInfo />
              <TravelerInfo reservation={reservation} />
              <FlightSlices />
              <AdditionalInfo />
              <SummaryOfCharges />
              <SectionDivider />
              {!!shouldShowRefundBreakdowns && (
                <>
                  <RefundBreakdowns refunds={reservation.refundBreakdowns} />
                  <SectionDivider />
                </>
              )}
            </PaddedItineraryContainer>
            {!!shouldShowPreviewMap && <GoogleFlightMap itinerary={reservation} isMobile={true} />}
          </Box>
          <ManageFlight />
        </>
      )}
    </>
  );
};

export default FlightItineraryContentBody;
